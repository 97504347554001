/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState, useCallback, useMemo} from 'react'
import ApexCharts from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../_metronic/partials'

import axios from 'axios'
import {useUser} from '../../../modules/newauth/UserContext'
import firebase from '../../../firebase/FirebaseConfig'
import {useParams} from 'react-router-dom'
import {format} from 'date-fns'

const ChartLineAPIUsagesWidget = React.memo(({className}) => {
  const chartRef = useRef(null)
  const {mode} = useThemeMode()

  const {user} = useUser()
  const db = firebase.firestore()
  const {id} = useParams()

  const [dataChartTime, setDataChartTime] = useState([])
  const [dataChartValue, setDataChartValue] = useState([])

  const [chartRange, setChartRange] = useState(2)

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const chartOptions = getChartOptions(dataChartTime, dataChartValue) // Adjust accordingly if you're passing more state variables
    const chart = new ApexCharts(chartRef.current, chartOptions)
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    async function fetchData() {
      try {
        db.collection('api')
          .doc(id)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              const timestampInSeconds = Math.floor(Date.now() / 1000)
              const timestampInSecondsToStop = timestampInSeconds - 3600

              const response = await axios.get(
                `https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1/history/range/${timestampInSeconds}/${
                  timestampInSeconds - 3600 * chartRange
                }`,
                {
                  headers: {
                    Authorization: `Bearer ${doc.data().apiKey}`,
                    'X-API-SECRET': doc.data().apiSecret,
                    'Content-Type': 'application/json',
                  },
                }
              )

              const data = response.data.data

              const array = await findNextDivisibleByFiveMinuteTimestamp(data, chartRange)

              setDataChartTime(
                array.map((item) => format(new Date(item.startTimeStamp * 1000), 'HH:mm'))
              )
              setDataChartValue(array.map((item) => item.transaction_count))
            } else {
              console.log('No such document!')
            }
          })
          .catch((error) => {
            console.log('Error getting document:', error)
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [chartRange])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, dataChartValue])

  return (
    <div
      className={`card card-xl-stretch mb-5 mb-xl-8 h-md-100`}
      style={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      }}
    >
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Recent Requests </span>

          <span className='text-muted fw-semibold fs-7'>In last 2 hour</span>
        </h3>

        <div className='card-toolbar' data-kt-buttons='true'>
          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
              chartRange === 2 ? 'active' : ''
            }`}
            onClick={() => setChartRange(2)}
          >
            Last 2 Hour
          </a>

          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
              chartRange === 12 ? 'active' : ''
            }`}
            onClick={() => setChartRange(12)}
          >
            Last 12 Hour
          </a>

          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
              chartRange === 24 ? 'active' : ''
            }`}
            onClick={() => setChartRange(24)}
          >
            Last 1 Day
          </a>
        </div>
      </div>

      <div className='card-body'>
        <div ref={chartRef} id='kt_charts_widget_3_chart'></div>
      </div>
    </div>
  )
})

export {ChartLineAPIUsagesWidget}

function getChartOptions(dataChartTime, dataChartValue) {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const lightColor = getCSSVariableValue('--bs-primary-light')

  return {
    series: [
      {
        name: 'Request ',
        data: dataChartValue,
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: 'zoom',
      },
      responsive: [
        {
          breakpoint: 480, // ตั้งค่า breakpoint ที่ 480px
          options: {
            chart: {
              width: '100%', // กำหนดให้กราฟมีความกว้างเต็มของ container
              height: '100%',
            },
            legend: {
              position: 'bottom', // ปรับตำแหน่งของ legend ไปอยู่ด้านล่าง
            },
          },
        },
      ],
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: dataChartTime,
      tickAmount: 10,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          return val + ' requests'
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

async function findNextDivisibleByFiveMinuteTimestamp(data, chartRange) {
  let now = new Date() // รับเวลาปัจจุบัน
  let minutes = now.getMinutes() // รับนาทีปัจจุบัน
  let remainder = 5 - (minutes % 5) // คำนวณเวลาที่ต้องเพิ่มเพื่อให้นาทีถัดไปหารด้วย 5 ลงตัว
  if (remainder !== 5) {
    // ถ้านาทีปัจจุบันไม่หารด้วย 5 ลงตัว
    now.setMinutes(minutes + remainder) // เซ็ตเวลาให้เป็นนาทีถัดไปที่หารด้วย 5 ลงตัว
    now.setSeconds(0) // ตั้งค่าวินาทีเป็น 0 เพื่อรับเวลาที่แน่นอน
    now.setMilliseconds(0) // ตั้งค่ามิลลิวินาทีเป็น 0
  }

  const startTimeStamp = Math.floor(now.getTime() / 1000) // คืนค่า timestamp ในหน่วยวินาที

  let array = []

  for (let index = 0; index < chartRange * 12; index++) {
    array.push({
      startTimeStamp: startTimeStamp - 300 * index,
      endTimeStamp: startTimeStamp - 300 * (index + 1) + 1,
      transaction_count: 0,
    })
  }

  for (let index = 0; index < data.length; index++) {
    for (let index2 = 0; index2 < array.length; index2++) {
      if (
        data[index].timestamp >= array[index2].endTimeStamp &&
        data[index].timestamp <= array[index2].startTimeStamp
      ) {
        array[index2].transaction_count =
          array[index2].transaction_count + parseInt(data[index].transaction_count)
      }
    }
  }

  array.reverse()

  return array
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {Dropdown1} from '../../../../_metronic/partials'
import {useLocation} from 'react-router'
import {useEffect, useState} from 'react'
import {useProject} from '../../../modules/project/ProjectContext'

import {toAbsoluteUrl, KTIcon} from '../../../../_metronic/helpers'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import firebase from '../../../firebase/FirebaseConfig'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash} from '@fortawesome/free-solid-svg-icons'

const DeleteProject = (props) => {
  const {id} = useParams()
  const MySwal = withReactContent(Swal)
  const db = firebase.firestore()
  let navigate = useNavigate()
  const [projectName, setProjectName] = useState('')

  const {project, loadProject} = useProject()

  useEffect(() => {
    if (id) {
      loadProject(id)
    }
  }, [id, loadProject])

  useEffect(() => {
    if (project !== null) {
      setProjectName(project.projectName)
    }
  }, [project])

  const deleteHandle = () => {
    MySwal.fire({
      title: 'Delete project',
      html: `<h4>Your project will also be deleted from the Malin1 and APIs consoles.</h4> <br><br> <h5>To delete your project, please type <strong>Delete ${projectName}</strong>.</h5>`,
      icon: 'warning',
      input: 'text',
      inputPlaceholder: `Delete ${projectName}`,
      width: '600px', // ตั้งค่าความกว้างของหน้าต่าง
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      confirmButtonColor: '#f1416c',
      cancelButtonColor: '#009ef7',
      confirmButtonText: 'Delete project',
      showLoaderOnConfirm: true,
      preConfirm: (inputValue) => {
        // ตรวจสอบว่าผู้ใช้ได้ป้อน "Delete <ชื่อโปรเจกต์>" หรือไม่
        if (inputValue.toLowerCase() !== `delete ${projectName}`.toLowerCase()) {
          Swal.showValidationMessage(
            `Request failed: You need to type "Delete ${projectName}" to confirm.`
          )
          return false
        }
        return true
      },
    }).then((result) => {
      if (result.isConfirmed) {
        // เพิ่มโค้ดเพื่อลบโปรเจกต์ที่นี่

        console.log('delete project')

        MySwal.fire({
          title: 'Wait a moment, the project is being delete!',
          html: 'Initializing...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading()
          },
        })

        db.collection('api')
          .doc(id)
          .delete()
          .then(async () => {
            console.log('Document successfully deleted!')
            await new Promise((resolve) => setTimeout(resolve, 2000))

            MySwal.fire({
              title: 'Delete project complete!',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                navigate('/overview')
              }
            })
          })
          .catch((error) => {
            console.error('Error removing document: ', error)

            MySwal.fire({
              title: 'Delete project not complete!',
              html: error,
              icon: 'error',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                // navigate('/overview')
              }
            })
          })
      }
    })
  }

  //   async function createProjectConfirmSubmit(values) {
  //     const MySwal2 = withReactContent(Swal)
  //     console.log(values)

  //     MySwal2.fire({
  //       title: 'Wait a moment, the project is being created!',
  //       html: 'Initializing...',
  //       showConfirmButton: false, // ไม่แสดงปุ่มยืนยัน
  //       // allowOutsideClick: false,
  //       didOpen: () => {
  //         MySwal2.showLoading()
  //       },
  //     })

  //     await new Promise((resolve) => setTimeout(resolve, 2000))
  //     MySwal2.update({
  //       html: 'Generating API Key and Secret...',
  //     })
  //     MySwal2.showLoading()

  //     const {apiKey, apiSecret} = generateApiKeyAndSecret()
  //     // console.log(`API Key: ${apiKey}`)
  //     // console.log(`API Secret: ${apiSecret}`)

  //     await new Promise((resolve) => setTimeout(resolve, 2000))
  //     MySwal2.update({
  //       html: 'Generating API Key and Secret... Done!',
  //     })
  //     MySwal2.showLoading()

  //     await new Promise((resolve) => setTimeout(resolve, 2000))
  //     MySwal2.update({
  //       html: 'Createing project...',
  //     })
  //     MySwal2.showLoading()

  //     var nowForStartBill = new Date()
  //     nowForStartBill.setDate(1)
  //     nowForStartBill.setHours(0, 0, 0, 0)
  //     const timestampForStartBill = Math.floor(nowForStartBill.getTime() / 1000)

  //     var nowForStopBill = new Date()
  //     if (nowForStopBill.getMonth() === 11) {
  //       nowForStopBill.setFullYear(nowForStopBill.getFullYear() + 1, 0, 1)
  //     } else {
  //       nowForStopBill.setMonth(nowForStopBill.getMonth() + 1, 1)
  //     }
  //     nowForStopBill.setHours(0, 0, 0, 0)
  //     const timestampForStopBill = Math.floor(nowForStopBill.getTime() / 1000)

  //     // Add a new document with a generated id.
  //     var timestampInSeconds = Math.floor(Date.now() / 1000)
  //     await db
  //       .collection('api')
  //       .add({
  //         accessControl: {},
  //         apiDetail: {
  //           endPoint: '',
  //           method: '',
  //           version: '',
  //         },
  //         apiKey: apiKey,
  //         apiSecret: apiSecret,
  //         billingInfo: {
  //           billingCycleEndDate: timestampForStopBill,
  //           billingCycleStartDate: timestampForStartBill,
  //           lastResetDate: timestampInSeconds,
  //         },
  //         cretedOn: timestampInSeconds,
  //         description: values.shortened_description,
  //         environment: 'development',
  //         lastUpdated: timestampInSeconds,
  //         owner: user.user_id,
  //         planType: 'free',
  //         projectId: '',
  //         projectName: values.project_name,
  //         projectType: values.project_type,
  //         projectCategory: values.project_category,
  //         projectImageProfile: '',
  //         usage: {
  //           currentUsage: 0,
  //           startUsing: timestampInSeconds,
  //           usageLimit: 1000,
  //         },
  //         usageStatistic: {},
  //       })
  //       .then((docRef) => {
  //         console.log('Document written with ID: ', docRef.id)
  //       })
  //       .catch((error) => {
  //         console.error('Error adding document: ', error)
  //       })

  //     await new Promise((resolve) => setTimeout(resolve, 2000))
  //     MySwal2.update({
  //       html: 'Createing project... Done!',
  //     })
  //     MySwal2.showLoading()

  //     await new Promise((resolve) => setTimeout(resolve, 2000))

  //     MySwal2.fire({
  //       title: 'Create project complete!',
  //       icon: 'success',
  //       confirmButtonText: 'OK',
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         navigate('/overview')
  //       }
  //     })
  //   }

  return (
    <>
      <center>
        <div
          class='btn btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger'
          style={{width: '30%'}}
          onClick={() => deleteHandle()}
        >
          <FontAwesomeIcon icon={faTrash} /> {'  '}
          Delete project
        </div>
      </center>
    </>
  )
}

export {DeleteProject}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'

import {useState} from 'react'
import firebase from '../../../firebase/FirebaseConfig'
import DataTable from 'react-data-table-component'
import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios'

const HistoryTable = (props) => {
  const {id} = useParams()
  const db = firebase.firestore()

  const [history, setHistory] = useState([])

  useEffect(() => {
    async function fetchData() {
      try {
        db.collection('api')
          .doc(id)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              const response = await axios.get(
                'https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1/history/lastest/30',
                {
                  headers: {
                    Authorization: `Bearer ${doc.data().apiKey}`,
                    'X-API-SECRET': doc.data().apiSecret,
                    'Content-Type': 'application/json',
                  },
                }
              )

              const data = response.data.data

              let deviceCount = 0
              let parameterCount = 0
              let parameterValueCount = 0

              for (let index = 0; index < data.length; index++) {
                data[index].timestamp_formated = await formatDate(data[index].timestamp)

                if (data[index].path.includes('device')) {
                  deviceCount += parseInt(data[index].transaction_count)
                }
                if (data[index].path.includes('parameter')) {
                  parameterCount += parseInt(data[index].transaction_count)
                }
                if (data[index].path.includes('parameter-value')) {
                  parameterValueCount += parseInt(data[index].transaction_count)
                }
              }

              setHistory(data)
              props.setDeviceCount(deviceCount)
              props.setParameterCount(parameterCount)
              props.setParameterValueCount(parameterValueCount)
            } else {
              console.log('No such document!')
            }
          })
          .catch((error) => {
            console.log('Error getting document:', error)
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  const columns = [
    {
      name: 'Route',
      selector: (row) => row.path,
      sortable: true,
    },
    {
      name: 'Time',
      selector: (row) => row.timestamp_formated,
      sortable: true,
      cell: (row) => <>{row.timestamp_formated}</>,
    },
    {
      name: 'Transaction Count',
      selector: (row) => row.transaction_count,
      sortable: true,
    },
  ]

  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <h3 className='card-title'>API History</h3>
        <div className='card-toolbar'>
          <span className='badge badge-light-primary'>Active</span>
        </div>
      </div>
      <div className='card-body'>
        <DataTable
          columns={columns}
          data={history}
          pagination
          paginationPerPage={10}
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
        />
      </div>
    </div>
  )
}

export {HistoryTable}

async function formatDate(timestamp) {
  const date = new Date(timestamp * 1000) // Convert to milliseconds by multiplying by 1000

  const formattedDate = date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })
  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  })

  const formattedDateTime = `${formattedDate} ${formattedTime}`
  return formattedDateTime
}

import Prism from 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-json' // เป็นตัวอย่างสำหรับ Python

import {useEffect} from 'react'

const ParametersEndPointSection = () => {
  useEffect(() => {
    Prism.highlightAll()
  }, [])

  return (
    <>
      <h1 id='parameter-endpoint-section' className='fw-bolder'>
        Parameter Endpoints
      </h1>
      <div className='text-gray-700 fs-5 my-7'>
        Parameter endpoints allow retrieval of data related to parameters. These endpoints enable
        access to detailed information about each parameter that the provided API key has access to.
      </div>

      <div className='separator border-3 my-10'></div>

      <h3 className='fw-bolder'>/v1/parameters - Get a List of All Accessible Parameters</h3>
      <div className='text-gray-700 fs-5 my-5'>
        This endpoint returns a list of all parameters accessible by the API key, along with their
        details.
      </div>

      <h5 className='fw-bolder'>Example Request : </h5>

      <pre className='my-5' style={{borderRadius: '10px'}}>
        <code className={`language-sh`}>
          {`GET https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1/parameters`}
        </code>
      </pre>

      <div className='row'>
        <div className='col-6'>
          <table className='table table-striped gs-7 gy-4 gx-7'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th style={{width: '40%'}}>Response Headers</th>
                <th style={{width: '60%'}}>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>status</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>
                  The status of the request. Returns "success" if the request was successfully
                  processed.
                </td>
              </tr>
              <tr>
                <td>
                  <span>data</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Object
                  </span>
                </td>
                <td>Contains the data for the request in JSON format.</td>
              </tr>

              <tr>
                <td>
                  <span>{`data.{parameter ID}`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The unique identifier for the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>{`status`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>Indicates whether the device is 'owner' or 'shared'.</td>
              </tr>

              <tr>
                <td>
                  <span>{`permission`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Array
                  </span>
                </td>
                <td>Lists the permissions for the device, such as 'read', 'write', 'delete'.</td>
              </tr>

              <tr>
                <td>
                  <span>{`name`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The name of the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>{`icon`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The URL for the parameter's icon image.</td>
              </tr>

              <tr>
                <td>
                  <span>{`premium`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Boolean
                  </span>
                </td>
                <td>Indicates whether the device is a premium or standard device.</td>
              </tr>

              <tr>
                <td>
                  <span>{`last_timestamp`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The last timestamp when the device updated data from its parameters.</td>
              </tr>

              <tr>
                <td>
                  <span>{`current_value`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Object
                  </span>
                </td>
                <td>
                  Object containing the current value of the parameter and additional details.
                </td>
              </tr>

              <tr>
                <td>
                  <span>{`current_value.value`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The current value of the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>{`current_value.status`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>
                  The current status of the parameter, which can be "N" for normal, "O" for over,
                  and "U" for under.
                </td>
              </tr>

              <tr>
                <td>
                  <span>{`current_value.timestamp`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The timestamp of the last update to the parameter's value.</td>
              </tr>

              <tr>
                <td>
                  <span>{`current_value.roc_value`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The current rate of change value of the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>{`current_value.roc_status`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>
                  The status of the parameter's rate of change, which can also be "N" for normal,
                  "O" for over, and "U" for under.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='col-6'>
          <h5 className='fw-bolder'>Example output</h5>

          <pre style={{borderRadius: '10px'}}>
            <code className={`language-json`}>
              {`{
    "status": "success",
    "data": {
        "Vl4isKNsn6BAWT0t0Hue": {
            "status": "owner",
            "permission": [
                "read",
                "write",
                "delete"
            ],
            "name": "Voltage Avg",
            "icon": "https://firebasestorage.googleapis.com/v0/...",
            "premium": true,
            "last_timestamp": 1660015102,
            "current_value": {
                "value": "",
                "status": "",
                "timestamp": "",
                "roc_value": "",
                "roc_status": ""
            }
        },
        "bc3ab8d9646647448035": {
            "status": "shared",
            "permission": [
                "read"
            ],
            "name": "Temperature",
            "icon": "https://firebasestorage.googleapis.com/v0/...",
            "premium": true,
            "last_timestamp": 1686037807,
            "current_value": {
                "value": 26,
                "status": "N",
                "timestamp": 1709721520,
                "roc_value": "0.00",
                "roc_status": "N"
            }
        }
    }
}`}
            </code>
          </pre>
        </div>
      </div>

      <div className='separator border-3 my-10'></div>

      <h3 className='fw-bolder'>{`/v1/parameters/{parameterID} - Get Data of a Specific Parameter`}</h3>
      <div className='text-gray-700 fs-5 my-5'>
        This endpoint provides detailed information about a specific parameter, identified by its
        parameter ID.
      </div>

      <h5 className='fw-bolder'>Example Request : </h5>

      <pre className='my-5' style={{borderRadius: '10px'}}>
        <code className={`language-sh`}>
          {`GET https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1/parameters/bc3ab8d9646647448035`}
        </code>
      </pre>

      <div className='row'>
        <div className='col-6'>
          <table className='table table-striped gs-7 gy-4 gx-7'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th style={{width: '40%'}}>Response Headers</th>
                <th style={{width: '60%'}}>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>status</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>
                  The status of the request. Returns "success" if the request was successfully
                  processed.
                </td>
              </tr>
              <tr>
                <td>
                  <span>data</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Object
                  </span>
                </td>
                <td>Contains the data for the request in JSON format.</td>
              </tr>

              <tr>
                <td>
                  <span>{`parameter_id`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The unique identifier for the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>{`name`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The name of the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>{`owner`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The owner ID of the device.</td>
              </tr>

              <tr>
                <td>
                  <span>{`premium`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Boolean
                  </span>
                </td>
                <td>Indicates whether the device is a premium or standard device.</td>
              </tr>

              <tr>
                <td>
                  <span>{`icon`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The URL for the parameter's icon image.</td>
              </tr>

              <tr>
                <td>
                  <span>{`last_timestamp`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The last timestamp when the device updated data from its parameters.</td>
              </tr>

              <tr>
                <td>
                  <span>{`limit_status`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>
                  The current limit status of the parameter, which can be "N" for normal, "O" for
                  over, and "U" for under.
                </td>
              </tr>

              <tr>
                <td>
                  <span>{`current_value`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The current value of the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>{`roc_status`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>
                  The current status related to the rate of change of the parameter, also can be "N"
                  for normal, "O" for over, and "U" for under.
                </td>
              </tr>

              <tr>
                <td>
                  <span>{`roc_value`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The current rate of change value of the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>{`current_status`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The current overall status of the parameter, similar to "limit_status".</td>
              </tr>

              <tr>
                <td>
                  <span>{`min`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The minimum limit for the parameter value.</td>
              </tr>

              <tr>
                <td>
                  <span>{`max`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The maximum limit for the parameter value.</td>
              </tr>

              <tr>
                <td>
                  <span>{`unit`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The unit of measurement for the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>{`roc_min`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The minimum limit for the rate of change of the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>{`roc_max`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The maximum limit for the rate of change of the parameter.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='col-6'>
          <pre style={{borderRadius: '10px'}}>
            <code className={`language-json`}>
              {`{
    "status": "success",
    "data": {
        "parameter_id": "bc3ab8d9646647448035",
        "name": "Temperature-opor33",
        "owner": "0ctGGnIn1vQWPrgWOd35OSVUir12",
        "premium": true,
        "icon": "https://firebasestorage.googleapis.com/v0/...",
        "last_timestamp": 1686037807,
        "limit_status": "N",
        "current_value": 26,
        "roc_status": "N",
        "roc_value": "0.00",
        "current_status": "offline",
        "min": 10,
        "max": 50,
        "unit": "°C",
        "roc_min": -2,
        "roc_max": 2
    }
}`}
            </code>
          </pre>
        </div>
      </div>
    </>
  )
}

export {ParametersEndPointSection}

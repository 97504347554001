import {PageTitle} from '../../../_metronic/layout/core'
import {useUser} from '../../modules/newauth/UserContext'
import firebase from '../../firebase/FirebaseConfig'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import axios from 'axios'

import {APIUsagesWidget} from './widget/APIUsagesWidget'
import {HistoryTable} from './widget/HistoryTable'
import {StatisticsWidget5} from '../../../_metronic/partials/widgets'
import {TotalRequestWidget} from './widget/TotalRequestWidget'
import {PlanWidget} from './widget/PlanWidget'
import {LastRequestWidget} from './widget/LastRequestWidget'

import {ChartBarUsagesWidget} from './widget/ChartBarUsagesWidget'
import {ChartPieUsagesWidget} from './widget/ChartPieUsagesWidget'
import {ChartLineUsagesWidget} from './widget/ChartLineUsagesWidget'

import {APIActivitiesWidget} from './widget/APIActivitiesWidget'
import {faSlash} from '@fortawesome/free-solid-svg-icons'
import {useNavigate} from 'react-router-dom'
import {useProject} from '../../modules/project/ProjectContext'

const ProjectUsagesAndHistoryOverviewPage = () => {
  const {user} = useUser()
  const db = firebase.firestore()
  const {id} = useParams()
  const navigate = useNavigate()

  const [projectName, setProjectName] = useState('')
  const [currentUsage, setCurrentUsage] = useState('')
  const [usageLimit, setUsageLimit] = useState('')

  const [projectData, setProjectData] = useState({})

  const [deviceCount, setDeviceCount] = useState(0)
  const [parameterCount, setParameterCount] = useState(0)
  const [parameterValueCount, setParameterValueCount] = useState(0)

  const [chartRange, setChartRange] = useState(2)

  const [dataChart, setDataChart] = useState([])

  const [apiUsageRangeLoading, setApiUsageRangeLoading] = useState(false)

  const {project, loadProject} = useProject()
  useEffect(() => {
    if (id) {
      loadProject(id)
    }
  }, [id, loadProject])

  useEffect(() => {
    if (project !== null) {
      if (user.user_id === project.owner) {
        // console.log('owner')
      } else {
        navigate('/overview')
      }
    }
  }, [project])

  useEffect(() => {
    async function fetchData() {
      try {
        db.collection('api')
          .doc(id)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              setProjectName(doc.data().projectName)
              setCurrentUsage(doc.data().usage.currentUsage)
              setUsageLimit(doc.data().usage.usageLimit)
              setProjectData(doc.data())

              const timestampInSeconds = Math.floor(Date.now() / 1000)
              const timestampInSecondsToStop = timestampInSeconds - 3600

              setApiUsageRangeLoading(true)

              const response = await axios.get(
                `https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1/history/range/${timestampInSeconds}/${
                  timestampInSeconds - 3600 * chartRange
                }`,
                {
                  headers: {
                    Authorization: `Bearer ${doc.data().apiKey}`,
                    'X-API-SECRET': doc.data().apiSecret,
                    'Content-Type': 'application/json',
                  },
                }
              )

              const data = response.data.data
              setDataChart(data)

              setApiUsageRangeLoading(false)
            } else {
              // doc.data() will be undefined in this case
              console.log('No such document!')
            }
          })
          .catch((error) => {
            console.log('Error getting document:', error)
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [chartRange])

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          Usages & History
          {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span> */}
        </h3>

        {/* <div className='d-flex flex-wrap my-2'>
          <div className='me-4'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='Active'
            >
              <option value='Active'>Active</option>
              <option value='Approved'>In Progress</option>
              <option value='Declined'>To Do</option>
              <option value='In Progress'>Completed</option>
            </select>
          </div>
          <a
            href='#'
            className='btn btn-primary btn-sm'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_project'
          >
            New Project
          </a>
        </div> */}
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <TotalRequestWidget svgIcon='basket' iconColor='white' projectData={projectData} />
        </div>

        <div className='col-xl-4'>
          <PlanWidget svgIcon='cheque' iconColor='white' projectData={projectData} />
        </div>

        <div className='col-xl-4'>
          <LastRequestWidget svgIcon='cheque' iconColor='white' projectData={projectData} />
        </div>
      </div>

      <div className='card card-custom g-5 g-xl-8'>
        <div className='card-header'>
          <h3 className='card-title'>API Usages</h3>
          <div className='card-toolbar' data-kt-buttons='true'>
            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-info px-4 me-1 ${
                chartRange === 2 ? 'active' : ''
              }`}
              onClick={() => setChartRange(2)}
            >
              2 Hour{' '}
              {chartRange === 2 && apiUsageRangeLoading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              ) : (
                ''
              )}
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-info px-4 me-1 ${
                chartRange === 12 ? 'active' : ''
              }`}
              onClick={() => setChartRange(12)}
            >
              12 Hour{' '}
              {chartRange === 12 && apiUsageRangeLoading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              ) : (
                ''
              )}
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-info px-4 me-1 ${
                chartRange === 24 ? 'active' : ''
              }`}
              onClick={() => setChartRange(24)}
            >
              1 Day{' '}
              {chartRange === 24 && apiUsageRangeLoading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              ) : (
                ''
              )}
            </a>

            <a
              className={`btn btn-sm btn-color-muted btn-active btn-active-info px-4 me-1 ${
                chartRange === 24 * 7 ? 'active' : ''
              }`}
              onClick={() => setChartRange(24 * 7)}
            >
              1 Week{' '}
              {chartRange === 24 * 7 && apiUsageRangeLoading ? (
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              ) : (
                ''
              )}
            </a>
          </div>
        </div>
        <div className='card-body'>
          <div className='row g-5 g-xl-8'>
            <div
              className='card-container'
              style={{display: 'flex', flexWrap: 'wrap', gap: '20px'}}
            >
              <ChartBarUsagesWidget dataChart={dataChart} />

              <ChartPieUsagesWidget dataChart={dataChart} />

              <ChartLineUsagesWidget dataChart={dataChart} chartRange={chartRange} />
            </div>
          </div>
        </div>
      </div>
      <br></br>
      <div className='row'>
        <div className='col-xl-4'>
          <APIActivitiesWidget className='card-xl-stretch mb-xl-8' />
        </div>
        <div className='col-xl-8'>
          <HistoryTable
            setDeviceCount={setDeviceCount}
            setParameterCount={setParameterCount}
            setParameterValueCount={setParameterValueCount}
          ></HistoryTable>
        </div>
      </div>

      {/* <div className='row'>
        <div className='col-xl-4'>
          <APIUsagesWidget
            className='card-xl-stretch mb-xl-8'
            color='primary'
            currentUsage={currentUsage}
            usageLimit={usageLimit}
            deviceCount={deviceCount}
            parameterCount={parameterCount}
            parameterValueCount={parameterValueCount}
          />
        </div>
      </div> */}
    </>
  )
}

const ProjectUsagesAndHistory = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Usages & History'}</PageTitle>
      <ProjectUsagesAndHistoryOverviewPage />
    </>
  )
}

export {ProjectUsagesAndHistory}

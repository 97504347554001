import Prism from 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-json' // เป็นตัวอย่างสำหรับ Python

import {useEffect} from 'react'

const DevicesEndPointSection = () => {
  useEffect(() => {
    Prism.highlightAll()
  }, [])

  return (
    <>
      <h1 id='device-endpoint-section' className='fw-bolder'>
        Device endpoints
      </h1>
      <div className='text-gray-700 fs-5 my-7'>
        Device endpoints will return data related to the device.
      </div>

      <div className='separator border-3 my-10'></div>

      <h3 className='fw-bolder'>/v1/devices - Get a List of All Devices</h3>
      <div className='text-gray-700 fs-5 my-5'>
        This endpoint returns a list of all devices that the provided API key has access to.
      </div>

      <h5 className='fw-bolder'>Example Request : </h5>

      <pre className='my-5' style={{borderRadius: '10px'}}>
        <code className={`language-sh`}>
          {`GET https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1/devices`}
        </code>
      </pre>

      <div className='row'>
        <div className='col-6'>
          <table className='table table-striped gs-7 gy-7 gx-7'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th style={{width: '40%'}}>Response Headers</th>
                <th style={{width: '60%'}}>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>status</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>
                  {' '}
                  The status of the request. Returns "success" if the request was successfully
                  processed.
                </td>
              </tr>
              <tr>
                <td>
                  <span>data</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Object
                  </span>
                </td>
                <td>Contains the data for the request in JSON format.</td>
              </tr>

              <tr>
                <td>
                  <span>{`data.{device ID}`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>Unique identifier for the device.</td>
              </tr>

              <tr>
                <td>
                  <span>{`status`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>Indicates whether the device is 'owner' or 'shared'.</td>
              </tr>

              <tr>
                <td>
                  <span>{`permission`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Array
                  </span>
                </td>
                <td>Lists the permissions for the device, such as 'read', 'write', 'delete'.</td>
              </tr>

              <tr>
                <td>
                  <span>{`name`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The name of the device.</td>
              </tr>

              <tr>
                <td>
                  <span>{`icon`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The URL of the device's icon image.</td>
              </tr>

              <tr>
                <td>
                  <span>{`last_status`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The last known status of the device, either 'online' or 'offline'.</td>
              </tr>

              <tr>
                <td>
                  <span>{`premium`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Boolean
                  </span>
                </td>
                <td> Indicates whether the device is a premium or standard device.</td>
              </tr>

              <tr>
                <td>
                  <span>{`last_timestamp`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The last timestamp when the device updated data from its parameters.</td>
              </tr>

              <tr>
                <td>
                  <span>{`update_interval`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The time interval in which the device sends data updates.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='col-6'>
          <h5 className='fw-bolder'>Example output</h5>

          <pre style={{borderRadius: '10px'}}>
            <code className={`language-json`}>
              {`{
    "status": "success",
    "data": {
        "1qYzQtSKYhdM88DpgR5Y": {
            "status": "owner",
            "permission": [
                "read"
            ],
            "name": "Mercury - OPOR",
            "icon": "https://firebasestorage.googleapis.com/v0/...",
            "last_status": "online",
            "premium": true,
            "last_timestamp": 1709719720,
            "update_interval": 1
        },
        "L5hdVPsN9h8aKzYwgXrF": {
            "status": "owner",
            "permission": [
                "read"
            ],
            "name": "STELLAR_ESP",
            "icon": "https://firebasestorage.googleapis.com/v0/...",
            "last_status": "offline",
            "premium": false,
            "last_timestamp": 1705303254,
            "update_interval": 10
        }
    }
}`}
            </code>
          </pre>
        </div>
      </div>

      <div className='separator border-3 my-10'></div>

      <h3 className='fw-bolder'>{`/v1/devices/{deviceID} - Get Data of a Specific Device`}</h3>
      <div className='text-gray-700 fs-5 my-5'>
        This endpoint retrieves detailed information about a specific device, identified by its
        device ID.
      </div>

      <h5 className='fw-bolder'>Example Request : </h5>

      <pre className='my-5' style={{borderRadius: '10px'}}>
        <code className={`language-sh`}>
          {`GET https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1/devices/1qYzQtSKYhdM88DpgR5Y`}
        </code>
      </pre>

      <div className='row'>
        <div className='col-6'>
          <table className='table table-striped gs-7 gy-7 gx-7'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th style={{width: '40%'}}>Response Headers</th>
                <th style={{width: '60%'}}>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>status</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The status of the request. Returns "success" if the request was successfully processed.</td>
              </tr>
              <tr>
                <td>
                  <span>data</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Object
                  </span>
                </td>
                <td>Contains the data for the request in JSON format.</td>
              </tr>

              <tr>
                <td>
                  <span>{`device_id`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>Unique identifier for the device.</td>
              </tr>

              <tr>
                <td>
                  <span>{`name`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The name of the device.</td>
              </tr>

              <tr>
                <td>
                  <span>{`owner`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The owner ID of the device.</td>
              </tr>

              <tr>
                <td>
                  <span>{`premium`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Boolean
                  </span>
                </td>
                <td>Indicates whether the device is a premium or standard device.</td>
              </tr>

              <tr>
                <td>
                  <span>{`icon`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The URL of the device's icon image.</td>
              </tr>

              <tr>
                <td>
                  <span>{`update_interval`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The time interval in which the device sends data updates.</td>
              </tr>

              <tr>
                <td>
                  <span>{`last_status`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The last known status of the device, either 'online' or 'offline'.</td>
              </tr>

              <tr>
                <td>
                  <span>{`last_timestamp`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The last timestamp when the device updated data from its parameters.</td>
              </tr>

              <tr>
                <td>
                  <span>{`parameter`}</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Array
                  </span>
                </td>
                <td>An array containing the IDs of parameters associated with the device.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='col-6'>
          <pre style={{borderRadius: '10px'}}>
            <code className={`language-json`}>
              {`{
    "status": "success",
    "data": {
        "device_id": "1qYzQtSKYhdM88DpgR5Y",
        "name": "Mercury - OPOR",
        "owner": "0ctGGnIn1vQWPrgWOd35OSVUir12",
        "premium": true,
        "icon": "https://firebasestorage.googleapis.com/v0/...",
        "update_interval": 1,
        "last_status": "offline",
        "last_timestamp": 1709721520,
        "parameter": [
            "bc3ab8d9646647448035",
            "9549b484d0bf4a4bb592"
        ]
    }
}`}
            </code>
          </pre>
        </div>
      </div>
    </>
  )
}

export {DevicesEndPointSection}

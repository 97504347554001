const StatusCodeSection = () => {
  return (
    <>
      <h1 id='status-codes-section' className='fw-bolder'>
        Status Codes
      </h1>
      <div className='text-gray-700 fs-5 my-5'>
        The following HTTP status codes may be returned after an API call, adhering to the standard
        HTTP status code specifications unless otherwise noted.
      </div>

      <table className='table gs-7 gy-7 gx-7'>
        <thead>
          <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
            <th style={{width: '40%'}}>Status code</th>
            <th style={{width: '60%'}}>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>200 OK</td>
            <td>Request successful.</td>
          </tr>
          <tr>
            <td>400 Bad Request</td>
            <td>Problem with the request.</td>
          </tr>
          <tr>
            <td>401 Unauthorized</td>
            <td>
              A valid authentication token is not provided or is incorrect. Ensure the correct
              authentication method and credentials are used.
            </td>
          </tr>
          <tr>
            <td>403 Forbidden</td>
            <td>
              Not authorized to access the resource. Confirm that your account or plan is authorized
              to access the resource.
            </td>
          </tr>
          <tr>
            <td>404 Not Found</td>
            <td>Unable to get information.</td>
          </tr>
          <tr>
            <td>500 Internal Server Error</td>
            <td>Error on the internal server.</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export {StatusCodeSection}

const AllEndPointSection = () => {
  return (
    <>
      <h1 className='fw-bolder'>All endpoints</h1>
      <div className='text-gray-700 fs-5 my-5'>All endpoints are listed below.</div>

      <div className='d-flex flex-column fw-bolder fs-6'>
        <li className='d-flex align-items-center py-2'>
          <a href='#device-endpoint-section'>
            <span className='bullet me-5'></span>/v1/devices
          </a>
        </li>
        <li className='d-flex align-items-center py-2'>
          <a href='#parameter-endpoint-section'>
            <span className='bullet me-5'></span>/v1/parameters
          </a>
        </li>
        <li className='d-flex align-items-center py-2'>
          <a href='#parameter-value-endpoint-section'>
            <span className='bullet me-5'></span>/v1/parameter-values
          </a>
        </li>
      </div>
    </>
  )
}

export {AllEndPointSection}

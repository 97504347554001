import React, { createContext, useContext, useState, useEffect } from 'react'

import * as icons from '@fortawesome/free-solid-svg-icons';


const IconContext = createContext()
export function useIcon() {
    return useContext(IconContext)
}

export function IconProvider({ children }) {

    // const iconList = [
    //     'faUser', 'faLocationDot', 'faBomb', 'faPaperclip', 'faShieldHalved', 'faBell', 'faCar', 'faGhost', 'faSun', 'faStar', 'faCloud', 'faCloudRain', 'faShuttleSpace',
    //     'faLightbulb', 'faWheatAwn', 'faTractor', 'faCow', 'faTree', 'faTreeCity',
    //     'faHouseSignal', 'faHouseFlag', 'faWarehouse', 'faBuilding', 'faCity',
    //     'faIndustry', 'faTowerObservation',
    // ];



    // type : general , weather , transport , agriculture , building

    const iconList2 = [
        {
            iconName: 'faUser',
            type: 'general'
        }, {
            iconName: 'faLocationDot',
            type: 'general'
        }, {
            iconName: 'faBomb',
            type: 'general'
        }, {
            iconName: 'faPaperclip',
            type: 'general'
        }, {
            iconName: 'faShieldHalved',
            type: 'general'
        }, {
            iconName: 'faBell',
            type: 'general'
        }, {
            iconName: 'faCar',
            type: 'transport'
        }, {
            iconName: 'faGhost',
            type: 'general'
        }, {
            iconName: 'faSun',
            type: 'weather'
        }, {
            iconName: 'faStar',
            type: 'weather'
        }, {
            iconName: 'faMoon',
            type: 'weather'
        }, {
            iconName: 'faCloud',
            type: 'weather'
        }, {
            iconName: 'faCloudRain',
            type: 'weather'
        }, {
            iconName: 'faWind',
            type: 'weather'
        }, {
            iconName: 'faTornado',
            type: 'weather'
        }, {
            iconName: 'faBoltLightning',
            type: 'weather'
        }, {
            iconName: 'faSnowflake',
            type: 'weather'
        }, {
            iconName: 'faRainbow',
            type: 'weather'
        }, {
            iconName: 'faMeteor',
            type: 'weather'
        }, {
            iconName: 'faHurricane',
            type: 'weather'
        }, {
            iconName: 'faBus',
            type: 'transport'
        }, {
            iconName: 'faVanShuttle',
            type: 'transport'
        }, {
            iconName: 'faTruck',
            type: 'transport'
        }, {
            iconName: 'faBicycle',
            type: 'transport'
        }, {
            iconName: 'faMotorcycle',
            type: 'transport'
        }, {
            iconName: 'faPlane',
            type: 'transport'
        }, {
            iconName: 'faShuttleSpace',
            type: 'transport'
        }, {
            iconName: 'faLightbulb',
            type: 'general'
        }, {
            iconName: 'faWheatAwn',
            type: 'agriculture'
        }, {
            iconName: 'faTractor',
            type: 'agriculture'
        }, {
            iconName: 'faCow',
            type: 'agriculture'
        }, {
            iconName: 'faTree',
            type: 'agriculture'
        }, {
            iconName: 'faTreeCity',
            type: 'building'
        }, {
            iconName: 'faHouseSignal',
            type: 'building'
        }, {
            iconName: 'faHouseFlag',
            type: 'building'
        }, {
            iconName: 'faWarehouse',
            type: 'building'
        }, {
            iconName: 'faBuilding',
            type: 'building'
        }, {
            iconName: 'faCity',
            type: 'building'
        }, {
            iconName: 'faIndustry',
            type: 'building'
        }, {
            iconName: 'faTowerObservation',
            type: 'building'
        },
    ];


    const iconData2 = iconList2.map(iconKey => ({
        value: iconKey.iconName.replace('fa', ''),
        label: iconKey.iconName.replace('fa', ''),
        icon: icons[iconKey.iconName],
        iconValue: iconKey.iconName,
        type: iconKey.type
    }));


    // const iconData = iconList.map(iconKey => ({
    //     value: iconKey.replace('fa', ''),
    //     label: iconKey.replace('fa', ''),
    //     icon: icons[iconKey],
    //     iconValue: iconKey,
    // }));

    const [icon, setIcon] = useState(iconData2)

    const iconMapping = iconList2.reduce((acc, { iconName }) => {
        acc[iconName] = icons[iconName];
        return acc;
    }, {});

    return (
        <IconContext.Provider value={{ icon, iconMapping }}>{children}</IconContext.Provider>
    )
}


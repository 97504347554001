import {PageTitle} from '../../../_metronic/layout/core'
import {useUser} from '../../modules/newauth/UserContext'
// import firebase from '../../firebase/FirebaseConfig'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {UsageTransactionWidget} from './widget/UsageTransactionWidget'
// import {StatisticsWidget6} from './widget/StatisticsWidget6'
// import {toAbsoluteUrl} from '../../../_metronic/helpers'

import {APIUsagesWidget} from './widget/APIUsagesWidget'
import {ExternalLinkWidget} from './widget/ExternalLinkWidget'
// import {AdvertiseWidget} from './widget/AdvertiseWidget'

// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faFolderOpen} from '@fortawesome/free-solid-svg-icons'

import {ProjectNameWidget} from './widget/ProjectnameWidget'

import {ChartLineAPIUsagesWidget} from './widget/ChartLineAPIUsagesWidget'
import {useNavigate} from 'react-router-dom'
import {useProject} from '../../modules/project/ProjectContext'

// import {
//   ListsWidget2,
//   ListsWidget3,
//   ListsWidget4,
//   ListsWidget6,
//   TablesWidget5,
//   TablesWidget10,
//   MixedWidget8,
//   CardsWidget7,
//   CardsWidget17,
//   CardsWidget20,
//   ListsWidget26,
//   EngageWidget10,
// } from '../../../_metronic/partials/widgets'

const ProjectOverviewPage = () => {
  const {user} = useUser()
  // const db = firebase.firestore()
  const {id} = useParams()
  const navigate = useNavigate()
  const {project, loadProject} = useProject()

  const [projectName, setProjectName] = useState('')
  const [projectId, setProjectId] = useState('')
  const [currentUsage, setCurrentUsage] = useState('')
  const [usageLimit, setUsageLimit] = useState('')
  const [projectData, setpProjectData] = useState('')

  useEffect(() => {
    if (id) {
      loadProject(id)
    }
  }, [id, loadProject])

  useEffect(() => {
    if (project !== null) {

      if (user.user_id === project.owner) {
        // console.log('owner')
      } else {
        navigate('/overview')
      }

      setProjectName(project.projectName)
      setCurrentUsage(project.usage.currentUsage)
      setUsageLimit(project.usage.usageLimit)
      setProjectId(project.id)
      setpProjectData(project)
    }
  }, [project])

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>Project Overview</h3>

        {/* <div className='d-flex flex-wrap my-2'>
          <div className='me-4'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='Active'
            >
              <option value='Active'>Active</option>
              <option value='Approved'>In Progress</option>
              <option value='Declined'>To Do</option>
              <option value='In Progress'>Completed</option>
            </select>
          </div>
        </div> */}
      </div>

      <>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
            <ProjectNameWidget
              className='h-md-50 mb-5 mb-xl-10'
              title={projectName}
              description='Project name'
              projectData={projectData}
            />

            <APIUsagesWidget currentUsage={currentUsage} usageLimit={usageLimit} />
          </div>

          <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
            <UsageTransactionWidget
              className='h-md-50 mb-5 mb-xl-10'
              color='info'
              title={`${currentUsage.toLocaleString()} / ${usageLimit.toLocaleString()}`}
              titleColor='white'
              description='Total API Requests'
              descriptionColor='white'
              currentUsage={currentUsage}
              usageLimit={usageLimit}
            />

            <ExternalLinkWidget className='h-lg-50' />
          </div>

          <div className='col-xxl-6'>
            {/* <AdvertiseWidget projectId={projectId} /> */}
            <ChartLineAPIUsagesWidget projectId={projectId} />
          </div>
        </div>

        {/* <div className='row gx-5 gx-xl-10'>
          <div className='col-xxl-6 mb-5 mb-xl-10'></div>

          <div className='col-xxl-6 mb-5 mb-xl-10'></div>
        </div>

        <div className='row gy-5 gx-xl-8'>
          <div className='col-xxl-4'>
            <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
          </div>
          <div className='col-xl-8'>
            <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
          </div>
        </div>

        <div className='row gy-5 g-xl-8'>
          <div className='col-xl-4'>
            <ListsWidget2 className='card-xl-stretch mb-xl-8' />
          </div>
          <div className='col-xl-4'>
            <ListsWidget6 className='card-xl-stretch mb-xl-8' />
          </div>
          <div className='col-xl-4'>
            <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
          </div>
        </div>

        <div className='row g-5 gx-xxl-8'>
          <div className='col-xxl-4'>
            <MixedWidget8
              className='card-xxl-stretch mb-xl-3'
              chartColor='success'
              chartHeight='150px'
            />
          </div>
          <div className='col-xxl-8'>
            <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
          </div>
        </div> */}
      </>

      {/* 
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='chart-pie-simple'
            color='primary'
            iconColor='white'
            title={projectName}
            titleColor='white'
            description='Project name'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-3'>
          <StatisticsWidget5
            className='card-xl-stretch mb-5 mb-xl-8'
            svgIcon='chart-pie-simple'
            color='info'
            iconColor='white'
            title={`${currentUsage.toLocaleString()} / ${usageLimit.toLocaleString()}`}
            titleColor='white'
            description='Usage transaction'
            descriptionColor='white'
          />
        </div>

        <div className='col-xl-6'>
          <StatisticsWidget6
            className='card-xl-stretch mb-xl-8'
            color='success'
            title=''
            description='Usage transaction percent'
            progress={`${((currentUsage / usageLimit) * 100).toFixed(2)}%`}
            borderColor='#50cd89'
          />
        </div>
      </div> */}
    </>
  )
}

const ProjectOverview = () => {
  const {id} = useParams()

  const [projectName, setProjectName] = useState('')
  const {project, loadProject} = useProject()

  useEffect(() => {
    if (id) {
      loadProject(id)
    }
  }, [id, loadProject])

  useEffect(() => {
    if (project !== null) {
      setProjectName(project.projectName)
    }
  }, [project])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{projectName}</PageTitle>
      <ProjectOverviewPage />
    </>
  )
}

export {ProjectOverview}

// function capitalizeFirstLetter(string) {
//   return string.charAt(0).toUpperCase() + string.slice(1)
// }

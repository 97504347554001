import {PageTitle} from '../../../_metronic/layout/core'
import {useUser} from '../../modules/newauth/UserContext'
import firebase from '../../firebase/FirebaseConfig'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {DevicePermissionTable} from './widget/DevicePermissionTable'
import {ParameterPermissionTable} from './widget/ParameterPermissionTable'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CryptoJS from 'crypto-js'
import {useProject} from '../../modules/project/ProjectContext'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClipboard} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

const ProjectApiManagementPage = () => {
  const {user} = useUser()
  const db = firebase.firestore()
  const {id} = useParams()
  const navigate = useNavigate()
  const MySwal = withReactContent(Swal)

  const [apiKey, setApiKey] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const [apiTrigger, setApiTrigger] = useState(false)

  const [showCopyApiKeyComplete, setShowCopyApiKeyComplete] = useState(false)
  const [textApiAlert, setTextApiAlert] = useState('')

  const {project, loadProject} = useProject()
  useEffect(() => {
    if (id) {
      loadProject(id)
    }
  }, [id, loadProject, apiTrigger])

  useEffect(() => {
    if (project !== null) {
      if (user.user_id === project.owner) {
        console.log('owner')
      } else {
        navigate('/overview')
      }

      setApiKey(project.apiKey)
      setApiSecret(project.apiSecret)
    }
  }, [project])

  const resetAPISecret = () => {
    MySwal.fire({
      title: 'Do you want to reset the key?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f1416c',
      cancelButtonColor: '#009ef7',
      confirmButtonText: 'Reset API Key',
    }).then((result) => {
      if (result.isConfirmed) {
        const {apiKey, apiSecret} = generateApiKeyAndSecret()

        db.collection('api')
          .doc(id)
          .update({
            apiSecret: apiSecret,
          })
          .then(() => {
            setApiTrigger(!apiTrigger)
            MySwal.fire({
              title: 'API Secret has been reset!',
              icon: 'success',
              confirmButtonText: 'OK',
            })
          })
          .catch((error) => {
            MySwal.fire({
              title: error,
              icon: 'error',
              confirmButtonText: 'OK',
            })
          })
      }
    })
  }

  const copyApiKeyHandle = (apiKey, text) => {
    setTextApiAlert(text)
    navigator.clipboard
      .writeText(apiKey)
      .then(() => {
        // Success message
        console.log('Text copied to clipboard')
        setShowCopyApiKeyComplete(true)
        setTimeout(() => {
          setShowCopyApiKeyComplete(false)
        }, 4500)
      })
      .catch((err) => {
        // Error handling
        console.log('Error in copying text: ', err)
      })
  }

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          API details
          {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span> */}
        </h3>

        {/* <div className='d-flex flex-wrap my-2'>
          <div className='me-4'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='Active'
            >
              <option value='Active'>Active</option>
              <option value='Approved'>In Progress</option>
              <option value='Declined'>To Do</option>
              <option value='In Progress'>Completed</option>
            </select>
          </div>
          <a
            href='#'
            className='btn btn-primary btn-sm'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_project'
          >
            New Project
          </a>
        </div> */}
      </div>

      <div
        className='card card-custom  mb-10'
        style={{
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        }}
      >
        <div className='card-header'>
          <h3 className='card-title'>API key</h3>
          <div className='card-toolbar'>
            <span className='badge badge-light-primary'>Active</span>
          </div>
        </div>
        <div className='card-body'>
          {/* <label className='form-label'>API Key</label> */}
          {/* <input type='text' className='form-control' value={apiKey} disabled /> */}

          <div className='row mb-5'>
            <label className='col-lg-3 fw-bold text-muted'>API Key</label>
            <div className='col-lg-7 d-flex align-items-start'>
              <span
                className='fw-bolder fs-6 text-dark'
                style={{wordWrap: 'break-word', maxHeight: '4rem', overflowY: 'auto'}}
              >
                {apiKey}
              </span>
            </div>
            <div className='col-lg-2 d-flex align-items-start'>
              <button
                className='btn btn-light-dark btn-sm'
                style={{width: '60%'}}
                onClick={() => copyApiKeyHandle(apiKey, 'API key')}
              >
                Copy
              </button>
            </div>
          </div>

          {/* <label className='form-label'>API Secret</label> */}
          {/* <input type='text' className='form-control' value={apiSecret} disabled /> */}
          <div className='row mb-5'>
            <label className='col-lg-3 fw-bold text-muted'>API Secret</label>
            <div className='col-lg-7 d-flex align-items-start'>
              <span
                className='fw-bolder fs-6 text-dark'
                style={{wordWrap: 'break-word', maxHeight: '5rem', overflowY: 'auto'}}
              >
                {apiSecret}
              </span>
            </div>
            <div className='col-lg-2 d-flex align-items-start'>
              <button
                className='btn btn-light-dark btn-sm'
                style={{width: '60%'}}
                onClick={() => copyApiKeyHandle(apiSecret, 'API secret')}
              >
                Copy
              </button>
            </div>
          </div>

          <div className='btn btn-light-danger btn-sm' onClick={() => resetAPISecret()}>
            Reset API Secret
          </div>
        </div>
      </div>

      <DevicePermissionTable></DevicePermissionTable>

      <ParameterPermissionTable></ParameterPermissionTable>


      {showCopyApiKeyComplete ? (
        <div
          className='alert-primary bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10'
          style={{position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000}}
        >
          <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'>
            <FontAwesomeIcon
              icon={faClipboard}
              className='fs-1x ms-n1'
              style={{color: '#009ef7'}}
            />
          </span>
          <div className='d-flex flex-column text-primary pe-0 pe-sm-10'>
            <h5 className='mb-1'>Copied</h5>
            <span>Copy {textApiAlert} complete. You can paste it to your application now.</span>
          </div>

          {/* <button
            type='button'
            className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
          >
            <span className='svg-icon svg-icon-1 svg-icon-primary'>X</span>
          </button> */}
        </div>
      ) : (
        ''
      )}
    </>
  )
}

const ProjectApiManagement = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'API Management'}</PageTitle>
      <ProjectApiManagementPage />
    </>
  )
}

export {ProjectApiManagement}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

function generateRandomString(length) {
  const randomBytes = CryptoJS.lib.WordArray.random(length / 2)
  return randomBytes.toString(CryptoJS.enc.Hex)
}

function generateApiKeyAndSecret(keyLength = 256, secretLength = 256) {
  const apiKey = generateRandomString(keyLength)
  const apiSecret = generateRandomString(secretLength)

  return {apiKey, apiSecret}
}

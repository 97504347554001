import {PageTitle} from '../../../_metronic/layout/core'
import {useUser} from '../../modules/newauth/UserContext'
import firebase from '../../firebase/FirebaseConfig'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import { useProject } from '../../modules/project/ProjectContext'
import {useNavigate} from 'react-router-dom'

import {PricingWidget1} from './widget/PricingWidget1'
import {PricingWidget2} from './widget/PricingWidget2'
import {PricingWidget3} from './widget/PricingWidget3'
import {PricingWidget4} from './widget/PricingWidget4'

const ProjectPricingPage = () => {
  const {user} = useUser()
  const db = firebase.firestore()
  const {id} = useParams()
  const navigate = useNavigate()

  const [projectName, setProjectName] = useState('')
  const [currentUsage, setCurrentUsage] = useState('')
  const [usageLimit, setUsageLimit] = useState('')

  const {project, loadProject} = useProject()
  useEffect(() => {
    if (id) {
      loadProject(id)
    }
  }, [id, loadProject])

  useEffect(() => {
    if (project !== null) {
      if (user.user_id === project.owner) {
        // console.log('owner')
      } else {
        navigate('/overview')
      }
    }
  }, [project])

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-4 justify-content-center align-items-center'>
        <h1 className='fw-bolder my-2 text-center' style={{fontSize: '2.5em'}}>
          Developers Pricing and Plans
        </h1>
      </div>

      <div className='d-flex flex-wrap flex-stack mb-6 justify-content-center align-items-center'>
        <h5 className='fw-bolder my-2'>
          Get started for free with 1000 requests/month forever. No credit card required.
        </h5>
      </div>

      <div className='d-flex flex-wrap flex-stack mb-6 justify-content-center align-items-center'>
        <button className='btn btn-primary'>Start For Free</button>
      </div>

      <div className='separator border-3 my-10'></div>

      <div className='d-flex flex-wrap flex-stack mb-5 justify-content-center align-items-center'>
        <h1 className='fw-bolder my-2 text-center' style={{fontSize: '2em'}}>
          Developers API Plans
        </h1>
      </div>

      <div className='row g-5 g-xl-7'>
        <div className='col-xl-3'>
          <PricingWidget1
            className='card-xl-stretch mb-xl-8'
            pricingText={'฿0 / mo'}
            apiCallPerMonth={'1,000'}
            emailSupportOnly={'Yes'}
            prioritySupport={'No'}
            aiSupport={'No'}
          />
        </div>
        <div className='col-xl-3'>
          <PricingWidget2
            className='card-xl-stretch mb-xl-8'
            apiCallPerMonth={'1,000'}
            emailSupportOnly={'Yes'}
            prioritySupport={'No'}
            aiSupport={'No'}
          />
        </div>

        <div className='col-xl-3'>
          <PricingWidget3
            className='card-xl-stretch mb-xl-8'
            apiCallPerMonth={'10,000'}
            emailSupportOnly={'Yes'}
            prioritySupport={'Yes'}
            aiSupport={'Yes'}
          />
        </div>

        <div className='col-xl-3'>
          <PricingWidget4
            className='card-xl-stretch mb-xl-8'
            apiCallPerMonth={'10,000'}
            emailSupportOnly={'Yes'}
            prioritySupport={'Yes'}
            aiSupport={'Yes'}
          />
        </div>
      </div>
    </>
  )
}

const ProjectPricing = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Plan & Pricing'}</PageTitle>
      <ProjectPricingPage />
    </>
  )
}

export {ProjectPricing}

const CommonSpecificationSection = () => {
  return (
    <>
      <h1 className='fw-bolder'>Common specifications</h1>
      {/* <div className='text-gray-700 fs-5 my-5'>
        Common specifications for API, such as the domain name of endpoints, response when
        a request succeeds or fails, and rate limit.
      </div> */}

      <div className='d-flex flex-column fw-bolder fs-6'>
        <li className='d-flex align-items-center py-2'>
          <a href='#domain-name-section'>
            <span className='bullet me-5'></span>Domain name
          </a>
        </li>
        <li className='d-flex align-items-center py-2'>
          <a href='#rate-limits-section'>
            <span className='bullet me-5'></span>Rate limits
          </a>
        </li>
        <li className='d-flex align-items-center py-2'>
          <a href='#request-headers-section'>
            <span className='bullet me-5'></span>Request headers
          </a>
        </li>
        <li className='d-flex align-items-center py-2'>
          <a href='#status-codes-section'>
            <span className='bullet me-5'></span>Status codes
          </a>
        </li>
        <li className='d-flex align-items-center py-2'>
          <a href='#response-headers-section'>
            <span className='bullet me-5'></span>Response headers
          </a>
        </li>
        <li className='d-flex align-items-center py-2'>
          <a href='#error-responses-section'>
            <span className='bullet me-5'></span>Error responses
          </a>
        </li>
        <li className='d-flex align-items-center py-2'>
          <a href='#getting-content-section'>
            <span className='bullet me-5'></span>Getting content
          </a>
        </li>
      </div>
    </>
  )
}



export {CommonSpecificationSection}

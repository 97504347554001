/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'

import {useState} from 'react'
import firebase from '../../../firebase/FirebaseConfig'
import DataTable from 'react-data-table-component'
import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {ImportDeviceModal} from '../modal/ImportDeviceModal'
import {useUser} from '../../../modules/newauth/UserContext'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {doc, updateDoc, deleteField} from 'firebase/firestore'

const DevicePermissionTable = () => {
  const {id} = useParams()
  const db = firebase.firestore()
  const MySwal = withReactContent(Swal)
  const [dataDevice, setDataDevice] = useState([])

  const [modelState, setModelState] = useState(false)
  const {user} = useUser()
  const [triggerDeviceTable, setTriggerDeviceTable] = useState(false)

  useEffect(() => {
    async function fetchData() {
      try {
        await db
          .collection('api')
          .doc(id)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              const keys = Object.keys(doc.data().accessControl)
              keys.sort()
              console.log(keys)
              let data = []

              for (let index = 0; index < keys.length; index++) {
                await db
                  .collection('device')
                  .doc(keys[index])
                  .get()
                  .then((doc2) => {
                    if (doc2.exists) {
                      data.push({
                        device_id: keys[index],
                        device_name: doc2.data().name,
                        device_owner: doc2.data().owner,
                        read_permission: doc
                          .data()
                          .accessControl[keys[index]].permission.includes('read'),
                        write_permission: doc
                          .data()
                          .accessControl[keys[index]].permission.includes('write'),
                        status: doc2.data().last_status,
                      })
                    } else {
                      console.log('No such document!')
                    }
                  })
                  .catch((error) => {
                    console.log('Error getting document:', error)
                  })
              }

              setDataDevice(data)
            } else {
              console.log('No such document!')
            }
          })
          .catch((error) => {
            console.log('Error getting document:', error)
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [triggerDeviceTable])

  const handleRemiveDevice = async (device_id) => {
    const docRef = db.collection('api').doc(id)

    await updateDoc(docRef, {
      [`accessControl.${device_id}`]: deleteField(),
    })
      .then(() => {
        MySwal.fire({
          title: 'Remove device complete.',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then(() => {
          setTriggerDeviceTable(!triggerDeviceTable)
        })
        console.log('Key removed successfully')
      })
      .catch((error) => {
        console.error('Error removing key:', error)
      })
  }

  const columns = [
    {
      name: 'Device ID',
      selector: (row) => row.device_id,
      sortable: true,
    },
    {
      name: 'Device name',
      selector: (row) => row.device_name,
      sortable: true,
    },
    {
      name: 'Device owner',
      selector: (row) => row.device_owner,
      sortable: true,
      cell: (row) =>
        row.device_owner === user.user_id ? (
          <span className='badge badge-info'>Me</span>
        ) : (
          row.device_owner
        ),
      center: 'true',
      style: {
        justifyContent: 'center', // This is just additional in case you need to adjust specific cell styles
      },
    },
    {
      name: 'Read permission',
      selector: (row) => row.read_permission,
      sortable: true,
      cell: (row) => {
        return (
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              id='flexSwitchChecked'
              checked={row.read_permission ? `checked` : ''}
              readOnly
            />
          </div>
        )
      },
      center: 'true',
      style: {
        justifyContent: 'center', // This is just additional in case you need to adjust specific cell styles
      },
    },
    {
      name: 'Write permission',
      selector: (row) => row.write_permission,
      sortable: true,
      cell: (row) => {
        return (
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              id='flexSwitchChecked'
              checked={row.write_permission ? `checked` : ''}
              readOnly
            />
          </div>
        )
      },
      center: 'true',
      style: {
        justifyContent: 'center',
      },
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        return (
          <span className={`badge badge-${row.status === 'online' ? 'success' : 'secondary'}`}>
            {row.status === 'online' ? `Online` : 'Offline'}
          </span>
        )
      },
      center: 'true',
      style: {
        justifyContent: 'center', // This is just additional in case you need to adjust specific cell styles
      },
    },

    {
      name: 'Delete',
      selector: (row) => row.device_id,
      sortable: true,
      cell: (row) => {
        return (
          <>
            <button
              className='btn btn-light-danger btn-sm'
              onClick={() => handleRemiveDevice(row.device_id)}
            >
              Remove
            </button>
          </>
        )
      },
      center: 'true',
      style: {
        justifyContent: 'center', // This is just additional in case you need to adjust specific cell styles
      },
    },
  ]

  const handleButtonClick = () => {
    setModelState(true) // Correctly updates the state when the button is clicked
  }

  return (
    <>
      <div
        className='card card-custom mb-10'
        style={{
          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        }}
      >
        <div className='card-header'>
          <h3 className='card-title'>Device permission</h3>
          <div className='card-toolbar'>
            <div className='btn btn-light-primary btn-sm' onClick={handleButtonClick}>
              Import device from Malin1
            </div>
          </div>
        </div>
        <div className='card-body'>
          <DataTable
            columns={columns}
            data={dataDevice}
            pagination
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25, 50, 100]}
          />
        </div>
      </div>

      <ImportDeviceModal
        modelState={modelState}
        setModelState={setModelState}
        triggerDeviceTable={triggerDeviceTable}
        setTriggerDeviceTable={setTriggerDeviceTable}
      ></ImportDeviceModal>
    </>
  )
}

export {DevicePermissionTable}

/* eslint-disable jsx-a11y/anchor-is-valid */

import {KTIcon} from '../../../../_metronic/helpers'
import {useUser} from '../../../modules/newauth/UserContext'
import firebase from '../../../firebase/FirebaseConfig'
import {useParams} from 'react-router-dom'
import {useEffect, useState} from 'react'
import axios from 'axios'

const APIUsagesWidget = (props) => {
  const {user} = useUser()
  const db = firebase.firestore()
  const {id} = useParams()

  const [totalRequests, setTotalRequests] = useState('')

  const [devicesCount, setDevicesCount] = useState('')
  const [parametersCount, setParametersCount] = useState('')
  const [parametersValuesCount, setpParametersValuesCount] = useState('')

  const [pathFirstText, setPathFirstText] = useState('')
  const [pathFirstValue, setPathFirstValue] = useState('')

  const [pathSecondText, setPathSecondText] = useState('')
  const [pathSecondValue, setPathSecondValue] = useState('')

  const [pathThirdText, setPathThirdText] = useState('')
  const [pathThirdValue, setPathThirdValue] = useState('')

  useEffect(() => {
    async function fetchData() {
      try {
        db.collection('api')
          .doc(id)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              // console.log(doc.data().apiKey)

              const timestampInSeconds = Math.floor(Date.now() / 1000)
              const response = await axios.get(
                `https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1/history/range/${timestampInSeconds}/${
                  timestampInSeconds - 3600
                }`,
                {
                  headers: {
                    Authorization: `Bearer ${doc.data().apiKey}`,
                    'X-API-SECRET': doc.data().apiSecret,
                    'Content-Type': 'application/json',
                  },
                }
              )

              setTotalRequests(response.data.data.length)

              const data = response.data.data

              const pathCounts = {}
              data.forEach((obj) => {
                const path = obj.path
                pathCounts[path] = (pathCounts[path] || 0) + 1
              })
              const pathCountsArray = Object.entries(pathCounts)
              pathCountsArray.sort((a, b) => b[1] - a[1])

              setPathFirstText(pathCountsArray[0][0])
              setPathFirstValue(pathCountsArray[0][1])

              setPathSecondText(pathCountsArray[1][0])
              setPathSecondValue(pathCountsArray[1][1])

              setPathThirdText(pathCountsArray[2][0])
              setPathThirdValue(pathCountsArray[2][1])

              let countLogDevices = 0
              let countLogParameters = 0
              let countLogParameterValues = 0

              for (let index = 0; index < data.length; index++) {
                const element = data[index]
                if (element.path.includes('/v1/devices')) {
                  countLogDevices++
                } else if (element.path.includes('/v1/parameters')) {
                  countLogParameters++
                } else if (element.path.includes('/v1/parameter-values')) {
                  countLogParameterValues++
                }
              }

              setDevicesCount(countLogDevices)
              setParametersCount(countLogParameters)
              setpParametersValuesCount(countLogParameterValues)
            } else {
              console.log('No such document!')
            }
          })
          .catch((error) => {
            // console.log('Error getting document:', error)
          })
      } catch (error) {
        // console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  return (
    <div
      className={`card card-flush h-md-50 mb-5 mb-xl-10`}
      style={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      }}
    >
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
              {totalRequests.toLocaleString()}
            </span>

            <span className='badge badge-light-success fs-base'>
              <KTIcon iconName='arrow-up' className='fs-5 text-success ms-n1' /> 2.2%
            </span>
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>API usage in 1 Hour</span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          <div className='d-flex fw-semibold align-items-center'>
            <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
            <div
              className='text-gray-500 flex-grow-1 me-4'
              style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
            >
              {pathFirstText}
            </div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>
              {pathFirstValue.toLocaleString()}
            </div>
          </div>
          <div className='d-flex fw-semibold align-items-center my-3'>
            <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'></div>
            <div
              className='text-gray-500 flex-grow-1 me-4'
              style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
            >
              {pathSecondText}
            </div>
            <div className='fw-bolder text-gray-700 text-xxl-end'>
              {pathSecondValue.toLocaleString()}
            </div>
          </div>
          <div className='d-flex fw-semibold align-items-center'>
            <div
              className='bullet w-8px h-3px rounded-2 me-3'
              style={{backgroundColor: '#E4E6EF'}}
            ></div>
            <div
              className='text-gray-500 flex-grow-1 me-4'
              style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
            >
              {pathThirdText}
            </div>
            <div className=' fw-bolder text-gray-700 text-xxl-end'>
              {pathThirdValue.toLocaleString()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {APIUsagesWidget}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMoneyBill1} from '@fortawesome/free-solid-svg-icons'

const PlanWidget = ({svgIcon, iconColor, projectData}) => {
  let colorBg2 = '#61ECBE'
  let colorBg1 = '#31DBDE'

  let fontColor = 'white'

  // if (projectData.planType === 'standard') {
  //   colorBg2 = '#2AB7FC'
  //   colorBg1 = '#15C0FE'
  //   fontColor = 'white'
  // } else if (projectData.planType === 'pro') {
  //   colorBg2 = '#15A5FE'
  //   colorBg1 = '#2A82FC'
  //   fontColor = 'white'
  // } else if (projectData.planType === 'custom') {
  colorBg2 = '#2A3FBF'
  colorBg1 = '#152082'
  fontColor = 'white'
  // }

  return (
    <div
      className={`card hoverable card-xl-stretch mb-xl-8`}
      style={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        // background: `linear-gradient(317deg, ${colorBg1} 0%, ${colorBg2} 100%)`,
        backgroundColor: '#1f93fd'

      }}
    >
      <div className='card-body'>
        <FontAwesomeIcon icon={faMoneyBill1} className='fs-2x ms-n1' style={{color: 'white'}} />

        <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>
          {capitalizeFirstLetter(projectData.planType)} Plan
        </div>

        <div className={`fw-semibold text-white`}>Project plan</div>
      </div>
    </div>
  )
}

export {PlanWidget}

function capitalizeFirstLetter(string) {
  if (typeof string === 'string' && string.length > 0) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  } else {
    return string
  }
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState, useCallback, useMemo} from 'react'
import ApexCharts from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../_metronic/partials'

import axios from 'axios'
import {useUser} from '../../../modules/newauth/UserContext'
import firebase from '../../../firebase/FirebaseConfig'
import {useParams} from 'react-router-dom'
import {format, set} from 'date-fns'

const ChartBarUsagesWidget = React.memo(({className, dataChart}) => {
  const chartRef = useRef(null)
  const {mode} = useThemeMode()

  const {user} = useUser()
  const db = firebase.firestore()
  const {id} = useParams()

  const [dataChartTime, setDataChartTime] = useState([])
  const [dataChartValue, setDataChartValue] = useState([])

  const [chartRange, setChartRange] = useState(2)

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }
    const chartOptions = getChartOptions(dataChartTime, dataChartValue) // Adjust accordingly if you're passing more state variables
    const chart = new ApexCharts(chartRef.current, chartOptions)
    if (chart) {
      chart.render()
    }
    return chart
  }

  useEffect(() => {
    async function fetchData() {
      const data = dataChart

      const pathCounts = {}
      data.forEach((obj) => {
        const path = obj.path
        pathCounts[path] = (pathCounts[path] || 0) + 1
      })
      const pathCountsArray = Object.entries(pathCounts)
      pathCountsArray.sort((a, b) => b[1] - a[1])

      setDataChartTime(pathCountsArray.map((item) => item[0]))
      setDataChartValue(pathCountsArray.map((item) => item[1]))
    }
    fetchData()
  }, [dataChart])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, dataChartValue])

  return (
    <div
      className={`card col-xl-8 h-md-100`}
      style={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        flex: '1',
        minWidth: '300px',
        maxWidth: '1fr',
      }}
    >
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>API Requests</span>
          {/* <span className='text-muted fw-semibold fs-7'>In last 2 hour</span> */}
        </h3>
      </div>

      <div
        className='card-body'
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <div ref={chartRef} id='kt_charts_widget_3_chart'></div>
      </div>
    </div>
  )
})

export {ChartBarUsagesWidget}

function getChartOptions(dataChartTime, dataChartValue) {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')

  return {
    series: [
      {
        data: dataChartValue,
      },
    ],
    chart: {
      type: 'bar',
      width: '100%',
      offsetX: -50,
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: dataChartTime,
    },
    colors: ['#7239ea'],
  }
}

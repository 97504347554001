/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {Dropdown1} from '../../../../_metronic/partials'
import {useLocation} from 'react-router'
import {useState} from 'react'

import {toAbsoluteUrl, KTIcon} from '../../../../_metronic/helpers'

import {Modal, Button} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faLaptopCode, faRocket} from '@fortawesome/free-solid-svg-icons'
import {useEffect} from 'react'
import firebase from '../../../firebase/FirebaseConfig'
import {useParams} from 'react-router-dom'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const ProjectDetail = (props) => {
  const location = useLocation()
  const {projectDetail} = props
  const db = firebase.firestore()
  const {id} = useParams()
  const MySwal = withReactContent(Swal)

  const [editProjectName, setEditProjectName] = useState(projectDetail.projectName)
  const [editProjectDescription, setEditProjectDescription] = useState(projectDetail.description)
  const [editEnvironmentType, setEditEnvironmentType] = useState(projectDetail.environment)

  useEffect(() => {
    setEditProjectName(projectDetail.projectName)
    setEditProjectDescription(projectDetail.description)
    setEditEnvironmentType(projectDetail.environment)
  }, [projectDetail.projectName, projectDetail.description, projectDetail.environment])

  const handleProjectTypeChange = (event) => {
    setEditEnvironmentType(event.target.value)
  }

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const submitUpdateProjectDetail = () => {
    if (id !== 'undefined') {
      let timestampInSeconds = Math.floor(Date.now() / 1000)
      db.collection('api')
        .doc(id)
        .update({
          projectName: editProjectName,
          description: editProjectDescription,
          environment: editEnvironmentType,
          lastUpdated: timestampInSeconds,
        })
        .then(() => {
          console.log('Document successfully updated!')
          props.setUpdateData(!props.updateData)

          MySwal.fire({
            title: 'Update project complete!',
            icon: 'success',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) {
              setShow(false)
            }
          })
        })
        .catch((error) => {
          console.error('Error updating document: ', error)
          MySwal.fire({
            title: 'Update project not complete!',
            text: error,
            icon: 'error',
          })
        })
    }
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Project Details</h3>
          </div>

          <div className='btn btn-secondary align-self-center btn-sm' onClick={() => handleShow()}>
            Edit Project Details
          </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Project Name</label>
            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{projectDetail.projectName}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Project Description</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{projectDetail.description}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Environment Type</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {capitalizeFirstLetter(projectDetail?.environment)}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Project Type</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {capitalizeFirstLetter(projectDetail?.projectType)}
              </span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Project Category</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>
                {formatTitle(projectDetail?.projectCategory || '')}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit project details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-6'>
            <label className='form-label'>Project name</label>
            <input
              type='text'
              className='form-control'
              value={editProjectName}
              onChange={(e) => setEditProjectName(e.target.value)}
            />
          </div>
          <div className='mb-6'>
            <label className='form-label'>Project description</label>
            <input
              type='text'
              className='form-control'
              value={editProjectDescription}
              onChange={(e) => setEditProjectDescription(e.target.value)}
            />
          </div>
          <div className='mb-0'>
            <label className='form-label'>Environment type</label>
            <div className='row'>
              <div className='col-lg-6'>
                <input
                  type='radio'
                  className='btn-check'
                  name='project_type'
                  value='development'
                  id='developmentInput'
                  onChange={handleProjectTypeChange}
                  checked={editEnvironmentType === 'development'}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                  htmlFor='developmentInput'
                >
                  <FontAwesomeIcon
                    icon={faLaptopCode}
                    className='fs-2x me-6'
                    style={
                      editEnvironmentType === 'development'
                        ? {color: '#009ef7', transition: 'color 0.5s ease'}
                        : {color: '#b0b0c2', transition: 'color 0.5s ease'}
                    }
                  />

                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-6 mb-2'>Development</span>
                  </span>
                </label>
              </div>

              <div className='col-lg-6'>
                <input
                  type='radio'
                  className='btn-check'
                  name='project_type'
                  value='production'
                  id='productionInput'
                  onChange={handleProjectTypeChange}
                  checked={editEnvironmentType === 'production'}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
                  htmlFor='productionInput'
                >
                  <FontAwesomeIcon
                    icon={faRocket}
                    className='fs-2x me-6'
                    style={
                      editEnvironmentType === 'production'
                        ? {color: '#dc3545', transition: 'color 0.5s ease'}
                        : {color: '#b0b0c2', transition: 'color 0.5s ease'}
                    }
                  />

                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-6 mb-2'>Production</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={submitUpdateProjectDetail}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {ProjectDetail}

function timestampToDate(timestamp) {
  var date = new Date(timestamp * 1000)
  var year = date.getUTCFullYear()
  var month = ('0' + (date.getUTCMonth() + 1)).slice(-2)
  var day = ('0' + date.getUTCDate()).slice(-2)
  var hours = ('0' + date.getUTCHours()).slice(-2)
  var minutes = ('0' + date.getUTCMinutes()).slice(-2)
  var seconds = ('0' + date.getUTCSeconds()).slice(-2)
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

function capitalizeFirstLetter(string) {
  // Check if the string is undefined, null, or empty
  if (string === undefined || string === null || string.trim() === '') {
    return string
  }

  // Ensure the input is treated as a string in case it's not
  const str = String(string)

  // Capitalize the first letter
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function formatTitle(str) {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

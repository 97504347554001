import {PageTitle} from '../../../_metronic/layout/core'
// import {Card2} from '../../../_metronic/partials/content/cards/Card2'
import {CardProject} from './widget/CardProject'
import {CreateProjectWidget} from './widget/CreateProjectWidget'
import {useUser} from '../../modules/newauth/UserContext'
import firebase from '../../firebase/FirebaseConfig'
import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'

const OverviewPage = () => {
  const {user} = useUser()
  const db = firebase.firestore()

  const [projectList, setProjectList] = useState([])

  useEffect(() => {
    async function fetchData() {
      try {
        let array = []

        db.collection('api')
          .where('owner', '==', user.user_id)
          .orderBy('projectName', 'asc')
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              array.push({id: doc.id, ...doc.data()})
            })
          })
          .then(() => {
            setProjectList(array)
          })
          .catch((error) => {
            console.log('Error getting documents: ', error)
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          All projects
          {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span> */}
        </h3>

        <div className='d-flex flex-wrap my-2'>
          {/* <div className='me-4'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='Active'
            >
              <option value='Active'>Active</option>
              <option value='Approved'>In Progress</option>
              <option value='Declined'>To Do</option>
              <option value='In Progress'>Completed</option>
            </select>
          </div> */}
          <Link to='/create-project' className='btn btn-primary btn-sm'>
            New Project
          </Link>
        </div>
      </div>

      <div className='row g-6 g-xl-9'>
        {projectList.map((item) => (
          <div className='col-md-6 col-xl-4' key={item.id}>
            <CardProject
              key={item.id}
              icon='/media/svg/brand-logos/plurk.svg'
              badgeColor={item.environment === 'production' ? 'success' : 'primary'}
              status={capitalizeFirstLetter(item.environment)}
              statusColor='primary'
              title={item.projectName}
              description={item.description}
              date='November 10, 2021'
              budget='$284,900.00'
              progress={100}
              url={`/project/${item.id}/overview`}
              detail_obj={item}
              icon_obj={item.icon}
            />
          </div>
        ))}
      </div>
    </>
  )
}

const users1 = [
  {name: 'Emma Smith', avatar: '/media/avatars/300-6.jpg'},
  {name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg'},
  {name: 'Susan Redwood', initials: 'S', color: 'primary'},
]

const users2 = [
  {name: 'Alan Warden', initials: 'A', color: 'warning'},
  {name: 'Brian Cox', avatar: '/media/avatars/300-5.jpg'},
]

const users3 = [
  {name: 'Mad Masy', avatar: '/media/avatars/300-6.jpg'},
  {name: 'Cris Willson', avatar: '/media/avatars/300-1.jpg'},
  {name: 'Mike Garcie', initials: 'M', color: 'info'},
]

const users4 = [
  {name: 'Nich Warden', initials: 'N', color: 'warning'},
  {name: 'Rob Otto', initials: 'R', color: 'success'},
]

const users5 = [
  {name: 'Francis Mitcham', avatar: '/media/avatars/300-20.jpg'},
  {name: 'Michelle Swanston', avatar: '/media/avatars/300-7.jpg'},
  {name: 'Susan Redwood', initials: 'S', color: 'primary'},
]

const users6 = [
  {name: 'Emma Smith', avatar: '/media/avatars/300-6.jpg'},
  {name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg'},
  {name: 'Susan Redwood', initials: 'S', color: 'primary'},
]

const users7 = [
  {name: 'Meloday Macy', avatar: '/media/avatars/300-2.jpg'},
  {name: 'Rabbin Watterman', initials: 'S', color: 'success'},
]

const users8 = [
  {name: 'Emma Smith', avatar: '/media/avatars/300-6.jpg'},
  {name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg'},
  {name: 'Susan Redwood', initials: 'S', color: 'primary'},
]

const users9 = [
  {name: 'Meloday Macy', avatar: '/media/avatars/300-2.jpg'},
  {name: 'Rabbin Watterman', initials: 'S', color: 'danger'},
]

const Overview = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Overview'}</PageTitle>
      <OverviewPage />
    </>
  )
}

export {Overview}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const ErrorResponsesSection = () => {
  return (
    <>
      <h1 id='error-responses-section' className='fw-bolder'>
        Error responses
      </h1>
      <div className='text-gray-700 fs-5 my-5'>
        The API server must return status error when request is not complete.
      </div>

      <table className='table gs-7 gy-7 gx-7'>
        <thead>
          <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
            <th style={{width: '40%'}}>Response headers</th>
            <th style={{width: '60%'}}>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>status</td>
            <td>Status of request, if error will return error.</td>
          </tr>
          <tr>
            <td>message</td>
            <td>Message of error.</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export {ErrorResponsesSection}

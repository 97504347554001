/* eslint-disable jsx-a11y/anchor-is-valid */
import firebase from '../../../firebase/FirebaseConfig'

import {useIcon} from '../../../modules/icon/IconContext'

import {KTIcon} from '../../../../_metronic/helpers'
import {format} from 'date-fns'
import {Modal, Button} from 'react-bootstrap'
import {useState} from 'react'

import {IconProfile} from './profile-icon-select/IconProfile'
import {useParams} from 'react-router-dom'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const AccountHeader = (props) => {
  const db = firebase.firestore()
  const {id} = useParams()
  const MySwal = withReactContent(Swal)

  const {icon, iconMapping} = useIcon()

  const [show, setShow] = useState(false)
  const [iconType, setIconType] = useState('icon')
  const [iconSelectedDetail, setIconSelectedDetail] = useState({})

  const [colorIcon, setColorIcon] = useState('#999999')
  const [colorBackgroundIcon, setColorBackgroundIcon] = useState('#000000')

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleIconTypeChange = (event) => {
    setIconType(event.target.value)
  }

  const handleIconSelect = (iconData) => {
    setIconSelectedDetail(iconData)
  }

  const handleSubmitIcon = () => {
    if (Object.keys(iconSelectedDetail).length === 0 && iconSelectedDetail.constructor === Object) {
    } else {
      const icons = icon

      function getIconValue(icons, value) {
        const iconObject = icons.find((icon) => icon.value === value)
        return iconObject ? iconObject.iconValue : 'Icon value not found'
      }

      const iconValue = getIconValue(icons, iconSelectedDetail)

      if (iconType === 'icon') {
        db.collection('api')
          .doc(id)
          .update({
            icon: {
              type: iconType,
              colorBackgroundIcon: colorBackgroundIcon,
              colorIcon: colorIcon,
              iconValue: iconValue,
              value: iconSelectedDetail,
            },
          })
          .then(() => {
            props.setUpdateData(!props.updateData)
            MySwal.fire({
              title: 'Update icon complete!',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) {
                setShow(false)
              }
            })
          })
          .catch((error) => {
            console.error('Error updating document: ', error)
            MySwal.fire({
              title: 'Update project not complete!',
              text: error,
              icon: 'error',
            })
          })
      }
    }
  }


  const iconName = props.projectDetail.icon?.iconValue // ตัวอย่าง 'house', 'user', 'car'
  const iconProfile = iconMapping[iconName]
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
            <div className='me-7 mb-4'>
              <div className='card card-custom overlay overflow-hidden'>
                <div className='card-body p-0'>
                  <div className='overlay-wrapper'>
                    {props.projectDetail.icon?.type === 'icon' ? (
                      <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                        <div className='symbol symbol-50px'>
                          <div
                            className='symbol-label '
                            style={{
                              backgroundColor: props.projectDetail.icon?.colorBackgroundIcon,
                              color: props.projectDetail.icon?.colorBackgroundIcon,
                            }}
                          >
                            <FontAwesomeIcon
                              icon={iconProfile}
                              className='fs-3x ms-n1'
                              style={{color: props.projectDetail.icon?.colorIcon}}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                        <div className='symbol symbol-50px'>
                          <div className='symbol-label fs-1 fw-bold bg-primary text-inverse-primary'>
                            P
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='overlay-layer bg-dark bg-opacity-10 align-items-end justify-content-center'>
                    <div
                      className='btn btn-warning btn-shadow d-flex flex-grow-1 flex-center py-5'
                      onClick={() => handleShow()}
                    >
                      Change
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <div className='symbol symbol-50px'>
                <div className='symbol-label fs-1 fw-bold bg-primary text-inverse-primary'>P</div>
              </div>
              <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
            </div> */}
            </div>

            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center mb-2'>
                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                      {props.projectDetail.projectName}
                    </a>
                    <a href='#'>
                      <KTIcon iconName='verify' className='fs-1 text-primary' />
                    </a>

                    <div
                      className='btn btn-sm btn-light-success fw-bolder ms-2 fs-8 py-1 px-3'
                      style={{
                        ...(props.projectDetail.planType === 'free'
                          ? {
                              // background: 'linear-gradient(45deg, #92FE9D, #00C9FF)',
                              background: '#E0E0E0',
                              color: 'black',
                            }
                          : props.projectDetail.planType === 'standard'
                          ? {
                              // background: 'linear-gradient(45deg, #00C9FF, #3faefb)',
                              background: '#00C9FF',
                              color: 'white',
                            }
                          : props.projectDetail.planType === 'pro'
                          ? {
                              // background: 'linear-gradient(45deg, #00C9FF, #3f5efb)',
                              background: '#3F5EFB',
                              color: 'white',
                            }
                          : {
                              background: 'linear-gradient(45deg, #3f5efb, #000146)',
                              color: 'white',
                            }),
                      }}
                    >
                      {capitalizeFirstLetter(props.projectDetail.planType)} Plan
                    </div>
                  </div>

                  <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='profile-circle' className='fs-4 me-1' />
                      {props.projectDetail.environment}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                    >
                      <KTIcon iconName='geolocation' className='fs-4 me-1' />
                      {props.projectDetail.projectCategory}
                    </a>
                    <a
                      href='#'
                      className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                    >
                      <KTIcon iconName='sms' className='fs-4 me-1' />
                      {props.projectDetail.projectType}
                    </a>
                  </div>
                </div>
              </div>

              <div className='d-flex flex-wrap flex-stack'>
                <div className='d-flex flex-column flex-grow-1 pe-8'>
                  <div className='d-flex flex-wrap'>
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-5 fw-bolder'>
                          {format(
                            new Date((props.projectDetail?.lastUpdated || 0) * 1000),
                            'MMMM d, HH:mm:ss'
                          )}
                        </div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Last update</div>
                    </div>

                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <KTIcon iconName='arrow-up' className='fs-3 text-success me-2' />
                        <div className='fs-5 fw-bolder'>
                          {props.projectDetail.usage?.currentUsage || 0} requests
                        </div>
                      </div>
                      <div className='fw-bold fs-6 text-gray-400'>Current transactions</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Change profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='mb-0'>
            <label className='form-label'>Profile icon type</label>
            <div className='row'>
              <div className='col-lg-4'>
                <input
                  type='radio'
                  className='btn-check'
                  name='project_type'
                  value='icon'
                  id='iconInput'
                  onChange={handleIconTypeChange}
                  checked={iconType === 'icon'}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-5 d-flex align-items-center mb-10'
                  htmlFor='iconInput'
                >
                  <KTIcon iconName={'icon'} className={`fs-2x ms-n1`} />

                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-6 mb-0'>Icon</span>
                  </span>
                </label>
              </div>

              {/* <div className='col-lg-4'>
                <input
                  type='radio'
                  className='btn-check'
                  name='project_type'
                  value='letter'
                  id='letterInput'
                  onChange={handleIconTypeChange}
                  checked={iconType === 'letter'}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-5 d-flex align-items-center'
                  htmlFor='letterInput'
                >
                  <KTIcon iconName={'text-bold'} className={`fs-2x ms-n1`} />
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-6 mb-0'>Letter</span>
                  </span>
                </label>
              </div>

              <div className='col-lg-4'>
                <input
                  type='radio'
                  className='btn-check'
                  name='project_type'
                  value='image'
                  id='imageInput'
                  onChange={handleIconTypeChange}
                  checked={iconType === 'image'}
                />
                <label
                  className='btn btn-outline btn-outline-dashed btn-outline-default p-5 d-flex align-items-center'
                  htmlFor='imageInput'
                >
                  <KTIcon iconName={'picture'} className={`fs-2x ms-n1`} />
                  <span className='d-block fw-bold text-start'>
                    <span className='text-dark fw-bolder d-block fs-6 mb-0'>Image</span>
                  </span>
                </label>
              </div> */}
            </div>
          </div>

          {iconType === 'icon' ? (
            <IconProfile
              onIconSelect={handleIconSelect}
              colorIcon={colorIcon}
              setColorIcon={setColorIcon}
              colorBackgroundIcon={colorBackgroundIcon}
              setColorBackgroundIcon={setColorBackgroundIcon}
              projectDetail={props.projectDetail}
            ></IconProfile>
          ) : (
            ''
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSubmitIcon}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {AccountHeader}

function capitalizeFirstLetter(string) {
  // Check if the string is undefined, null, or empty
  if (string === undefined || string === null || string.trim() === '') {
    return string
  }

  // Ensure the input is treated as a string in case it's not
  const str = String(string)

  // Capitalize the first letter
  return str.charAt(0).toUpperCase() + str.slice(1)
}

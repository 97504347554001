/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useIcon} from '../../../../modules/icon/IconContext'
import {SketchPicker, CompactPicker, TwitterPicker} from 'react-color'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const IconProfile = ({
  onIconSelect,
  colorIcon,
  setColorIcon,
  colorBackgroundIcon,
  setColorBackgroundIcon,
  projectDetail,
}) => {
  const {icon} = useIcon()

  const icons = icon

  const [showPickerIcon, setShowPickerIcon] = useState(false)
  const [showPickerBackgroundIcon, setShowPickerBackgroundIcon] = useState(false)
  const [iconSelect, setIconSelect] = useState('Sun')

  const handleChangeIconComplete = (color) => {
    setColorIcon(color.hex)
  }

  const handleChangeIconBackgroundComplete = (color) => {
    setColorBackgroundIcon(color.hex)
  }

  const handleIconChange = (event) => {
    setIconSelect(event.target.value)
    onIconSelect(event.target.value)
  }

  useEffect(() => {
    if (projectDetail.icon) {
      setColorIcon(projectDetail.icon.colorIcon)
      setColorBackgroundIcon(projectDetail.icon.colorBackgroundIcon)
      setIconSelect(projectDetail.icon.value)
    }
  }, [projectDetail?.icon])

  const index2 = icons.findIndex((icon) => icon.value === iconSelect)

  console.log(icons)
  return (
    <>
      <div className='mb-0'>
        <div className='row'>
          <div className='col-lg-3'>
            <div className='mb-5'>
              <div className='overlay-wrapper'>
                <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                  <div className='symbol symbol-50px'>
                    <div
                      className='symbol-label '
                      style={{backgroundColor: colorBackgroundIcon, color: colorBackgroundIcon}}
                    >
                      <FontAwesomeIcon
                        icon={icons[index2].icon}
                        className='fs-3x ms-n1'
                        style={{color: colorIcon}}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='mb-5'>
              <label className='form-label'>Icon color</label>
              <br></br>
              <button
                className='btn btn-lg'
                onClick={() => setShowPickerIcon((showPickerIcon) => !showPickerIcon)}
                style={{
                  color: 'white',
                  backgroundColor: colorIcon,
                  borderStyle: 'solid',
                  borderColor: 'black',
                }}
              ></button>
              {showPickerIcon && (
                <div style={{position: 'absolute', zIndex: 2}}>
                  <div
                    style={{
                      position: 'fixed',
                      top: '0px',
                      right: '0px',
                      bottom: '0px',
                      left: '0px',
                    }}
                    onClick={() => setShowPickerIcon(false)}
                  />
                  <TwitterPicker
                    colors={[
                      '#F78DA7',
                      '#f44336',
                      '#EB144C',
                      '#e91e63',
                      '#9c27b0',
                      '#673ab7',
                      '#3f51b5',
                      '#2196F3',
                      '#03A9F4',
                      '#00BCD4',
                      '#009688',
                      '#4CAF50',
                      '#8BC34A',
                      '#CDDC39',
                      '#FFEB3B',
                      '#FFC107',
                      '#FF9800',
                      '#795548',
                      '#9E9E9E',
                      '#FFFFFF',
                      '#000000',

                      // '#FCB900',
                      // '#7BDCB5',
                      // '#00D084',
                      // '#8ED1FC',
                      // '#0693E3',
                      // '#ABB8C3',
                      // '#F78DA7',
                      // '#9900EF',
                    ]}
                    color={colorIcon}
                    onChangeComplete={handleChangeIconComplete}
                  />
                </div>
              )}
            </div>

            <div className='mb-5'>
              <label className='form-label'>Icon background color</label>
              <br></br>

              <button
                className='btn btn-lg'
                onClick={() =>
                  setShowPickerBackgroundIcon(
                    (showPickerBackgroundIcon) => !showPickerBackgroundIcon
                  )
                }
                style={{
                  color: 'white',
                  backgroundColor: colorBackgroundIcon,
                  borderStyle: 'solid',
                  borderColor: 'black',
                }}
              ></button>
              {showPickerBackgroundIcon && (
                <div style={{position: 'absolute', zIndex: 2}}>
                  <div
                    style={{
                      position: 'fixed',
                      top: '0px',
                      right: '0px',
                      bottom: '0px',
                      left: '0px',
                    }}
                    onClick={() => setShowPickerBackgroundIcon(false)}
                  />
                  <TwitterPicker
                    color={colorBackgroundIcon}
                    onChangeComplete={handleChangeIconBackgroundComplete}
                    colors={[
                      '#F78DA7',
                      '#f44336',
                      '#EB144C',
                      '#e91e63',
                      '#9c27b0',
                      '#673ab7',
                      '#3f51b5',
                      '#2196F3',
                      '#03A9F4',
                      '#00BCD4',
                      '#009688',
                      '#4CAF50',
                      '#8BC34A',
                      '#CDDC39',
                      '#FFEB3B',
                      '#FFC107',
                      '#FF9800',
                      '#795548',
                      '#9E9E9E',
                      '#FFFFFF',
                      '#000000',

                      // '#FCB900',
                      // '#7BDCB5',
                      // '#00D084',
                      // '#8ED1FC',
                      // '#0693E3',
                      // '#ABB8C3',
                      // '#F78DA7',
                      // '#9900EF',
                    ]}
                  />
                </div>
              )}
            </div>
          </div>

          <div className='col-lg-9'>
            {/* <div className='mb-5'>
              <label className='form-label'>Profile icon type</label>
              <div className='row'>
                {icons.map((icon) => (
                  <div className='col-lg-3' key={icon.value}>
                    <input
                      type='radio'
                      className='btn-check'
                      name='iconSelect'
                      value={icon.value}
                      id={`id_${icon.value}`}
                      onChange={handleIconChange}
                      checked={iconSelect === icon.value}
                    />
                    <label
                      className='btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center mb-2'
                      htmlFor={`id_${icon.value}`}
                    >
                      <FontAwesomeIcon
                        icon={icon?.icon}
                        className='fs-2 ms-n1  me-3'
                        style={{
                          color: iconSelect === icon.value ? '#009ef7' : 'black',
                        }}
                      />
                      <span className='d-block text-start'>
                        <span
                          className='d-block fs-6 mb-0'
                          style={{
                            color: iconSelect === icon.value ? '#009ef7' : 'black',
                          }}
                        >
                          {' '}
                          {icon.label}
                        </span>
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </div> */}

            <div className='mb-3'>
              <label className='form-label'>General icon</label>
              <div className='row'>
                {icons
                  .filter((icon) => icon.type === 'general')
                  .map((icon) => (
                    <div className='col-lg-3' key={icon.value}>
                      <input
                        type='radio'
                        className='btn-check'
                        name='iconSelect'
                        value={icon.value}
                        id={`id_${icon.value}`}
                        onChange={handleIconChange}
                        checked={iconSelect === icon.value}
                      />
                      <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center mb-2'
                        htmlFor={`id_${icon.value}`}
                      >
                        <FontAwesomeIcon
                          icon={icon?.icon}
                          className='fs-2 ms-n1  me-3'
                          style={{
                            color: iconSelect === icon.value ? '#009ef7' : 'black',
                          }}
                        />
                        <span className='d-block text-start'>
                          <span
                            className='d-block fs-6 mb-0'
                            style={{
                              color: iconSelect === icon.value ? '#009ef7' : 'black',
                            }}
                          >
                            {' '}
                            {icon.label}
                          </span>
                        </span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>

            <div className='mb-3'>
              <label className='form-label'>Weather icon</label>
              <div className='row'>
                {icons
                  .filter((icon) => icon.type === 'weather')
                  .map((icon) => (
                    <div className='col-lg-3' key={icon.value}>
                      <input
                        type='radio'
                        className='btn-check'
                        name='iconSelect'
                        value={icon.value}
                        id={`id_${icon.value}`}
                        onChange={handleIconChange}
                        checked={iconSelect === icon.value}
                      />
                      <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center mb-2'
                        htmlFor={`id_${icon.value}`}
                      >
                        <FontAwesomeIcon
                          icon={icon?.icon}
                          className='fs-2 ms-n1  me-3'
                          style={{
                            color: iconSelect === icon.value ? '#009ef7' : 'black',
                          }}
                        />
                        <span className='d-block text-start'>
                          <span
                            className='d-block fs-6 mb-0'
                            style={{
                              color: iconSelect === icon.value ? '#009ef7' : 'black',
                            }}
                          >
                            {' '}
                            {icon.label}
                          </span>
                        </span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>

            <div className='mb-3'>
              <label className='form-label'>Transport icon</label>
              <div className='row'>
                {icons
                  .filter((icon) => icon.type === 'transport')
                  .map((icon) => (
                    <div className='col-lg-3' key={icon.value}>
                      <input
                        type='radio'
                        className='btn-check'
                        name='iconSelect'
                        value={icon.value}
                        id={`id_${icon.value}`}
                        onChange={handleIconChange}
                        checked={iconSelect === icon.value}
                      />
                      <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center mb-2'
                        htmlFor={`id_${icon.value}`}
                      >
                        <FontAwesomeIcon
                          icon={icon?.icon}
                          className='fs-2 ms-n1  me-3'
                          style={{
                            color: iconSelect === icon.value ? '#009ef7' : 'black',
                          }}
                        />
                        <span className='d-block text-start'>
                          <span
                            className='d-block fs-6 mb-0'
                            style={{
                              color: iconSelect === icon.value ? '#009ef7' : 'black',
                            }}
                          >
                            {' '}
                            {icon.label}
                          </span>
                        </span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>

            <div className='mb-3'>
              <label className='form-label'>Agriculture icon</label>
              <div className='row'>
                {icons
                  .filter((icon) => icon.type === 'agriculture')
                  .map((icon) => (
                    <div className='col-lg-3' key={icon.value}>
                      <input
                        type='radio'
                        className='btn-check'
                        name='iconSelect'
                        value={icon.value}
                        id={`id_${icon.value}`}
                        onChange={handleIconChange}
                        checked={iconSelect === icon.value}
                      />
                      <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center mb-2'
                        htmlFor={`id_${icon.value}`}
                      >
                        <FontAwesomeIcon
                          icon={icon?.icon}
                          className='fs-2 ms-n1  me-3'
                          style={{
                            color: iconSelect === icon.value ? '#009ef7' : 'black',
                          }}
                        />
                        <span className='d-block text-start'>
                          <span
                            className='d-block fs-6 mb-0'
                            style={{
                              color: iconSelect === icon.value ? '#009ef7' : 'black',
                            }}
                          >
                            {' '}
                            {icon.label}
                          </span>
                        </span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>

            <div className='mb-3'>
              <label className='form-label'>Building icon</label>
              <div className='row'>
                {icons
                  .filter((icon) => icon.type === 'building')
                  .map((icon) => (
                    <div className='col-lg-3' key={icon.value}>
                      <input
                        type='radio'
                        className='btn-check'
                        name='iconSelect'
                        value={icon.value}
                        id={`id_${icon.value}`}
                        onChange={handleIconChange}
                        checked={iconSelect === icon.value}
                      />
                      <label
                        className='btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center mb-2'
                        htmlFor={`id_${icon.value}`}
                      >
                        <FontAwesomeIcon
                          icon={icon?.icon}
                          className='fs-2 ms-n1  me-3'
                          style={{
                            color: iconSelect === icon.value ? '#009ef7' : 'black',
                          }}
                        />
                        <span className='d-block text-start'>
                          <span
                            className='d-block fs-6 mb-0'
                            style={{
                              color: iconSelect === icon.value ? '#009ef7' : 'black',
                            }}
                          >
                            {' '}
                            {icon.label}
                          </span>
                        </span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>

            {/* <div className='mb-5'>
              <label className='form-label'>Icon color</label>
              <br></br>
              <button
                className='btn btn-lg'
                onClick={() => setShowPickerIcon((showPickerIcon) => !showPickerIcon)}
                style={{
                  color: 'white',
                  backgroundColor: colorIcon,
                  borderStyle: 'solid',
                  borderColor: 'black',
                }}
              ></button>
              {showPickerIcon && (
                <div style={{position: 'absolute', zIndex: 2}}>
                  <div
                    style={{
                      position: 'fixed',
                      top: '0px',
                      right: '0px',
                      bottom: '0px',
                      left: '0px',
                    }}
                    onClick={() => setShowPickerIcon(false)}
                  />
                  <TwitterPicker color={colorIcon} onChangeComplete={handleChangeIconComplete} />
                </div>
              )}
            </div>

            <div className='mb-5'>
              <label className='form-label'>Icon background color</label>
              <br></br>

              <button
                className='btn btn-lg'
                onClick={() =>
                  setShowPickerBackgroundIcon(
                    (showPickerBackgroundIcon) => !showPickerBackgroundIcon
                  )
                }
                style={{
                  color: 'white',
                  backgroundColor: colorBackgroundIcon,
                  borderStyle: 'solid',
                  borderColor: 'black',
                }}
              ></button>
              {showPickerBackgroundIcon && (
                <div style={{position: 'absolute', zIndex: 2}}>
                  <div
                    style={{
                      position: 'fixed',
                      top: '0px',
                      right: '0px',
                      bottom: '0px',
                      left: '0px',
                    }}
                    onClick={() => setShowPickerBackgroundIcon(false)}
                  />
                  <TwitterPicker
                    color={colorBackgroundIcon}
                    onChangeComplete={handleChangeIconBackgroundComplete}
                  />
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export {IconProfile}

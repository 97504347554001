import {PageTitle} from '../../../_metronic/layout/core'
import {useUser} from '../../modules/newauth/UserContext'
import {useProject} from '../../modules/project/ProjectContext'
import firebase from '../../firebase/FirebaseConfig'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useNavigate} from 'react-router-dom'

import {AccountHeader} from './widget/AccoutHeader'
import {ProjectDetail} from './widget/ProjectDetail'
import {DeleteProject} from './widget/DeleteProject'

const ProjectSettingPage = () => {
  const {user} = useUser()
  const db = firebase.firestore()
  const {id} = useParams()
  const navigate = useNavigate()

  const [projectName, setProjectName] = useState('')
  const [currentUsage, setCurrentUsage] = useState('')
  const [usageLimit, setUsageLimit] = useState('')
  const [projectDetail, setProjectDetail] = useState({})
  const [updateData, setUpdateData] = useState(false)

  const {project, loadProject} = useProject()

  useEffect(() => {
    if (id) {
      loadProject(id)
    }
  }, [id, loadProject, updateData])

  useEffect(() => {
    if (project !== null) {
      if (user.user_id === project.owner) {
        // console.log('owner')
      } else {
        navigate('/overview')
      }

      setProjectDetail(project)
      setProjectName(project.projectName)
      setCurrentUsage(project.usage.currentUsage)
      setUsageLimit(project.usage.usageLimit)
    }
  }, [project])

  return (
    <>
      <div className='d-flex flex-wrap flex-stack mb-6'>
        <h3 className='fw-bolder my-2'>
          Project Setting
          {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span> */}
        </h3>

        {/* <div className='d-flex flex-wrap my-2'>
          <div className='me-4'>
            <select
              name='status'
              data-control='select2'
              data-hide-search='true'
              className='form-select form-select-sm form-select-white w-125px'
              defaultValue='Active'
            >
              <option value='Active'>Active</option>
              <option value='Approved'>In Progress</option>
              <option value='Declined'>To Do</option>
              <option value='In Progress'>Completed</option>
            </select>
          </div>
          <a
            href='#'
            className='btn btn-primary btn-sm'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_project'
          >
            New Project
          </a>
        </div> */}
      </div>

      <AccountHeader
        projectDetail={projectDetail}
        updateData={updateData}
        setUpdateData={setUpdateData}
      />
      <ProjectDetail
        projectDetail={projectDetail}
        updateData={updateData}
        setUpdateData={setUpdateData}
      />

      <DeleteProject />
    </>
  )
}

const ProjectSetting = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Project Setting'}</PageTitle>
      <ProjectSettingPage />
    </>
  )
}

export {ProjectSetting}

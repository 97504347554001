/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faStopwatch} from '@fortawesome/free-solid-svg-icons'
import {useUser} from '../../../modules/newauth/UserContext'
import firebase from '../../../firebase/FirebaseConfig'
import {useParams} from 'react-router-dom'
import {useEffect} from 'react'
import axios from 'axios'
import {format} from 'date-fns'

const LastRequestWidget = ({projectData}) => {
  let colorBg2 = '#31DBDE'
  let colorBg1 = '#00C9FF'

  let fontColor = 'white'

  // if (projectData.planType === 'standard') {
  //   colorBg2 = '#3FAEFB'
  //   colorBg1 = '#2AB7FC'
  //   fontColor = 'white'
  // } else if (projectData.planType === 'pro') {
  //   colorBg2 = '#2A82FC'
  //   colorBg1 = '#3f5efb'
  //   fontColor = 'white'
  // } else if (projectData.planType === 'custom') {
  colorBg2 = '#152082'
  colorBg1 = '#000146'
  fontColor = 'white'
  // }

  const {user} = useUser()
  const db = firebase.firestore()
  const {id} = useParams()

  const [textUpdate, setTextUpdate] = React.useState('')

  useEffect(() => {
    async function fetchData() {
      try {
        db.collection('api')
          .doc(id)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              const timestampInSeconds = Math.floor(Date.now() / 1000)
              const timestampInSecondsToStop = timestampInSeconds - 3600

              const response = await axios.get(
                `https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1/history/lastest/1`,
                {
                  headers: {
                    Authorization: `Bearer ${doc.data().apiKey}`,
                    'X-API-SECRET': doc.data().apiSecret,
                    'Content-Type': 'application/json',
                  },
                }
              )
              const data = response.data.data
              const formattedDate = format(new Date(data[0].timestamp * 1000), 'MMMM d, HH:mm:ss')

              setTextUpdate(formattedDate)
            } else {
              console.log('No such document!')
            }
          })
          .catch((error) => {
            console.log('Error getting document:', error)
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  return (
    <div
      className={`card hoverable card-xl-stretch mb-xl-8`}
      style={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        // background: `linear-gradient(317deg, ${colorBg1} 0%, ${colorBg2} 100%)`,
        backgroundColor: '#3f5efb ',
      }}
    >
      <div className='card-body'>
        <FontAwesomeIcon icon={faStopwatch} className='fs-2x ms-n1' style={{color: 'white'}} />

        <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>{textUpdate}</div>

        <div className={`fw-semibold text-white`}>Last request</div>
      </div>
    </div>
  )
}

export {LastRequestWidget}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {KTIcon} from '../../../../_metronic/helpers'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCrown, faCloudArrowUp, faMaximize} from '@fortawesome/free-solid-svg-icons'

type Props = {
  className: string
  apiCallPerMonth: string
  emailSupportOnly: string
  prioritySupport: string
  aiSupport: string
}

const PricingWidget3: React.FC<Props> = ({
  className,
  apiCallPerMonth,
  emailSupportOnly,
  prioritySupport,
  aiSupport,
}) => {
  return (
    <div
      className={`card ${className}`}
      style={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      }}
    >
      <div className='card-body p-0'>
        <div
          className={`px-9 pt-7 card-rounded h-300px w-100 mb-3`}
          style={{
            // background: 'linear-gradient(317deg, #3f5efb 0%, #00C9FF 100%)',
            background: '#3F5EFB',
          }}
        >
          {/* bg-${color} */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>{'Pro Plan'}</h3>
            <div className='ms-1'>
              <button
                type='button'
                className={`btn btn-sm btn-icon btn-color-white border-0 me-n3`}
              >
                <KTIcon iconName='category' className='fs-2' />
              </button>
            </div>
          </div>
          <div className='d-flex text-center flex-column text-white pt-8'>
            {/* <span className='fw-semibold fs-5'>Pricing</span> */}
            <span className='fw-bold fs-2x pt-1 mb-3'>{'฿ 20,000 / mo'}</span>
            <span className='fw-semibold fs-6'>
              Take ownership of your request to maximize your API request.
            </span>
          </div>
        </div>

        <div
          className='shadow-xs card-rounded mx-5 mb-5 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <FontAwesomeIcon
                  icon={faCloudArrowUp}
                  className='fs-2'
                  style={{color: '#99a1b7'}}
                />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  50,000 request/mo
                </a>
                <div className='text-gray-400 fw-semibold fs-7'>Quota</div>
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <FontAwesomeIcon icon={faMaximize} className='fs-2' style={{color: '#99a1b7'}} />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  ฿ 0.10 / request
                </a>
                <div className='text-gray-400 fw-semibold fs-7'>Exceeds Limit</div>
              </div>
            </div>
          </div>

          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <FontAwesomeIcon icon={faCrown} className='fs-2' style={{color: '#99a1b7'}} />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  Priority Support
                </a>
                <div className='text-gray-400 fw-semibold fs-7'>Fastest Support 24/7</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {PricingWidget3}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'

import {useState} from 'react'
import firebase from '../../../firebase/FirebaseConfig'
import DataTable from 'react-data-table-component'
import {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Modal, Button} from 'react-bootstrap'

import ProgressBar from 'react-bootstrap/ProgressBar'

const ImportDeviceModal = (props) => {
  const {id} = useParams()
  const db = firebase.firestore()
  const MySwal = withReactContent(Swal)
  const [dataDevice, setDataDevice] = useState([])
  const [selectedRows, setSelectedRows] = useState([])

  useEffect(() => {
    async function fetchData() {
      try {
        await db
          .collection('api')
          .doc(id)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              const keys = Object.keys(doc.data().accessControl)

              let data = []
              await db
                .collection('device')
                .where('owner', '==', doc.data().owner)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc2) => {
                    if (!keys.includes(doc2.id)) {
                      data.push({
                        device_id: doc2.id,
                        device_name: doc2.data().name,
                        device_owner: doc2.data().owner,
                      })
                    }
                  })
                })
                .catch((error) => {
                  console.log('Error getting documents: ', error)
                })
              setDataDevice(data)
            } else {
              console.log('No such document!')
            }
          })
          .catch((error) => {
            console.log('Error getting document:', error)
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [props.triggerDeviceTable])

  const handleClose = () => {
    props.setModelState(false)
    setSelectedRows([])
  }

  const handleSubmit = async () => {
    if (selectedRows.length == 0) {
      MySwal.fire({
        title: 'Please select at least one device',
        icon: 'warning',
      })
    } else {
      MySwal.fire({
        title: 'Wait a moment,start adding device!',
        html: 'Initializing...',
        showConfirmButton: false, // ไม่แสดงปุ่มยืนยัน
        didOpen: () => {
          MySwal.showLoading()
        },
      })

      for (let index = 0; index < selectedRows.length; index++) {
        await new Promise((resolve) => setTimeout(resolve, 1500))

        const element = selectedRows[index]
        const docRef = db.collection('api').doc(id)
        const timestampInSeconds = Math.floor(Date.now() / 1000)
        let updateObject = {}

        updateObject[`accessControl.${element.device_id}`] = {
          createOn: timestampInSeconds,
          lastUpdate: timestampInSeconds,
          permission: ['read'],
        }

        try {
          await docRef.update(updateObject)
          MySwal.update({
            title: `Add device ${element.device_name} successfully!`,
            html: (
              <ProgressBar
                animated
                now={((index + 1) * 100) / selectedRows.length}
                label={`${index + 1} / ${selectedRows.length}`}
              />
            ),
            icon: 'success',
          })
        } catch (error) {
          console.error('Error updating document:', error)
        }
      }

      await new Promise((resolve) => setTimeout(resolve, 1500))

      MySwal.fire({
        title: 'All devices added.',
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        handleClose()
        props.setTriggerDeviceTable(!props.triggerDeviceTable)
      })
    }
  }

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows)
  }

  const columns = [
    {
      name: 'Device ID',
      selector: (row) => row.device_id,
      sortable: true,
    },
    {
      name: 'Device name',
      selector: (row) => row.device_name,
      sortable: true,
    },
    {
      name: 'Device owner',
      selector: (row) => row.device_owner,
      sortable: true,
    },
  ]

  return (
    <>
      <Modal show={props.modelState} onHide={handleClose} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Import device from Malin1</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DataTable
            columns={columns}
            data={dataDevice}
            pagination
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25, 50, 100]}
            selectableRows
            selectableRowsHighlight
            onSelectedRowsChange={handleRowSelected}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleClose}>
            Close
          </Button>
          <Button variant='primary' onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export {ImportDeviceModal}

const ResponseSection = () => {
  return (
    <>
      <h1 id='response-headers-section' className='fw-bolder'>
        Response
      </h1>
      <div className='text-gray-700 fs-5 my-5'>
        Upon receiving a complete HTTP GET request, the API server should respond with a status code
        of 200 to indicate successful processing of the request.
      </div>

      <table className='table gs-7 gy-7 gx-7'>
        <thead>
          <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
            <th style={{width: '40%'}}>Response headers</th>
            <th style={{width: '60%'}}>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>status</td>
            <td>Status of request, if success will return success.</td>
          </tr>
          <tr>
            <td>data</td>
            <td>Data of request, in JSON format.</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export {ResponseSection}

import Prism from 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-json' // เป็นตัวอย่างสำหรับ Python

import {useEffect} from 'react'

const ValuesEndPointSection = () => {
  useEffect(() => {
    Prism.highlightAll()
  }, [])

  return (
    <>
      <h1 id='parameter-value-endpoint-section' className='fw-bolder'>
        Value endpoints
      </h1>
      <div className='text-gray-700 fs-5 my-7'>
        Value endpoints will return value from parameter.
      </div>

      <div className='separator border-3 my-10'></div>

      <h3 className='fw-bolder'>{`/v1/parameter-values/{parameterID} - Get Current Values of a Parameter`}</h3>
      <div className='text-gray-700 fs-5 my-5'>
        This endpoint retrieves the most recent values and statuses for a specific parameter
        identified by its parameter ID.
      </div>

      <h5 className='fw-bolder'>Example request</h5>

      <pre className='my-5' style={{borderRadius: '10px'}}>
        <code className={`language-sh`}>
          {`GET https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1/parameter-values/bc3ab8d9646647448035`}
        </code>
      </pre>

      <div className='row'>
        <div className='col-6'>
          <table className='table table-striped gs-7 gy-4 gx-7'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th style={{width: '40%'}}>Response Headers</th>
                <th style={{width: '60%'}}>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>status</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>
                  The status of the request. Returns "success" if the request was successfully
                  processed.
                </td>
              </tr>
              <tr>
                <td>
                  <span>data</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Object
                  </span>
                </td>
                <td>Contains the data for the request in JSON format.</td>
              </tr>

              <tr>
                <td>
                  <span>parameter_id</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The unique identifier for the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>name</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The name of the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>limit_status</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>
                  The current status of the parameter, which can be "N" for normal, "O" for over,
                  and "U" for under.
                </td>
              </tr>

              <tr>
                <td>
                  <span>current_value</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The current value of the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>roc_status</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>
                  The status of the parameter's rate of change, which can also be "N" for normal,
                  "O" for over, and "U" for under.
                </td>
              </tr>

              <tr>
                <td>
                  <span>roc_value</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>The current rate of change value of the parameter.</td>
              </tr>

              <tr>
                <td>
                  <span>current_status</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>
                  The current status of the parameter, which can be "N" for normal, "O" for over,
                  and "U" for under.
                </td>
              </tr>

              <tr>
                <td>
                  <span>last_timestamp</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Number
                  </span>
                </td>
                <td>The last timestamp when the device updated data from its parameters.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='col-6'>
          <h5 className='fw-bolder'>Example output</h5>

          <pre style={{borderRadius: '10px'}}>
            <code className={`language-json`}>
              {`{
    "status": "success",
    "data": {
        "parameter_id": "bc3ab8d9646647448035",
        "name": "Temperature-opor33",
        "limit_status": "N",
        "current_value": 26,
        "roc_status": "N",
        "roc_value": "0.00",
        "current_status": "offline",
        "last_timestamp": 1686037807
    }
}`}
            </code>
          </pre>
        </div>
      </div>

      <div className='separator border-3 my-10'></div>

      <h3 className='fw-bolder'>{`/v1/parameter-values/{parameterID}/{stopTimestamp}/{startTimestamp} - Get Historical Data for a Parameter`}</h3>
      <div className='text-gray-700 fs-5 my-5'>
        This endpoint retrieves a series of data points for a specific parameter, identified by its
        parameter ID, between two timestamps.
      </div>

      <h5 className='fw-bolder'>Example Request : </h5>

      <pre className='my-5' style={{borderRadius: '10px'}}>
        <code className={`language-sh`}>
          {`GET https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1/parameter-values/bc3ab8d9646647448035/1707466183/1707455383`}
        </code>
      </pre>

      <div className='row'>
        <div className='col-6'>
          <table className='table table-striped gs-7 gy-4 gx-7'>
            <thead>
              <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                <th style={{width: '40%'}}>Response Headers</th>
                <th style={{width: '60%'}}>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>status</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    String
                  </span>
                </td>
                <td>
                  {' '}
                  The status of the request. Returns "success" if the request was successfully
                  processed.
                </td>
              </tr>
              <tr>
                <td>
                  <span>data</span>
                  <span className='badge badge-secondary' style={{marginLeft: '15px'}}>
                    Array
                  </span>
                </td>
                <td>
                  An array of [timestamp, value] pairs, where each pair represents a data point
                  within the specified time range. The timestamp is in Unix format, and the value is
                  the parameter's measurement at that timestamp.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='col-6'>
          <pre style={{borderRadius: '10px'}}>
            <code className={`language-json`}>
              {`{
    "status": "success",
    "data": [
        [
            1707459039,
            31.5
        ],
        [
            1707459098,
            31.91666603088379
        ],
        [
            1707459158,
            32
        ], ...
    ]
}`}
            </code>
          </pre>
        </div>
      </div>
    </>
  )
}

export {ValuesEndPointSection}

const RequestHeadersSection = () => {
  return (
    <>
      <h1 id='request-headers-section' className='fw-bolder'>Request headers</h1>
      <div className='text-gray-700 fs-5 my-5'></div>

      <table className='table gs-7 gy-7 gx-7'>
        <thead>
          <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
            <th style={{width: '40%'}}>Header</th>
            <th style={{width: '60%'}}>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Authorization</td>
            <td>Bearer 9eb021af-73f0-4088...</td>
          </tr>
          <tr>
            <td>X-API-SECRET</td>
            <td>2dc830c4b0ce...</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export {RequestHeadersSection}

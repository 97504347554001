import {PageTitle} from '../../../_metronic/layout/core'
import {useUser} from '../../modules/newauth/UserContext'
import firebase from '../../firebase/FirebaseConfig'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'

import {CommonSpecificationSection} from './section/CommonSpecificationSection'
import {DomainNameSection} from './section/DomainNameSection'
import {RateLimitsSection} from './section/RateLimitsSection'
import {StatusCodeSection} from './section/StatusCodeSection'
import {ResponseSection} from './section/ResponseSection'
import {ErrorResponsesSection} from './section/ErrorResponsesSection'
import {RequestHeadersSection} from './section/RequestHeadersSection'

import {AllEndPointSection} from './section/AllEndPointSection'
import {DevicesEndPointSection} from './section/DevicesEndPointSection'
import {ParametersEndPointSection} from './section/ParametersEndPointSection'
import {ValuesEndPointSection} from './section/ValuesEndPointSection'

const DocumentationPage = () => {
  const {user} = useUser()
  const db = firebase.firestore()
  const {id} = useParams()

  return (
    <>
      <div
        className='card card-custom'
        style={{boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'}}
      >
        <div className='card-body'>
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <p className='fw-bolder my-1' style={{fontSize: '2.5em'}}>
              API Documents
              {/* <span className='fs-6 text-gray-400 fw-bold ms-1'>Active</span> */}
            </p>
          </div>

          <div className='separator border-3 my-10'></div>
          <CommonSpecificationSection />

          <div className='separator border-3 my-10'></div>
          <div className='row'>
            <div className='col-md-6'>
              <DomainNameSection />
            </div>
            <div className='col-md-6'>
              <RateLimitsSection />
            </div>
          </div>

          <div className='separator border-3 my-10'></div>
          <div className='row'>
            <div className='col-md-6'>
              <RequestHeadersSection />
            </div>
            <div className='col-md-6'>
              <StatusCodeSection />
            </div>
          </div>

          <div className='separator border-3 my-10'></div>

          <div className='row'>
            <div className='col-md-6'>
              <ResponseSection />
            </div>
            <div className='col-md-6'>
              <ErrorResponsesSection />
            </div>
          </div>

          <div className='separator border-3 my-10'></div>

          <div id='getting-content-section' className='d-flex flex-wrap flex-stack mb-6'>
            <p className='fw-bolder my-1' style={{fontSize: '2.5em'}}>
              Getting content
            </p>
          </div>

          <AllEndPointSection />

          <div className='separator border-3 my-10'></div>

          <DevicesEndPointSection />

          <div className='separator border-3 my-10'></div>

          <ParametersEndPointSection />

          <div className='separator border-3 my-10'></div>

          <ValuesEndPointSection />
        </div>
      </div>
    </>
  )
}

const Documentation = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Documentations'}</PageTitle>
      <DocumentationPage />
    </>
  )
}

export {Documentation}

import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

import {Overview} from '../pages/overview/Overview'
import {CreateProject2} from '../pages/overview/create-project/CreateProject2'
import {ProjectOverview} from '../pages/project-overview/ProjectOverview'
import {ProjectApiManagement} from '../pages/project-api-management/ProjectApiManagement'
import {ProjectUsagesAndHistory} from '../pages/project-usages/ProjectUsagesAndHistoryOverview'
import {ProjectPricing} from '../pages/project-pricing/ProjectPricing'
import {ProjectSetting} from '../pages/project-setting/ProjectSetting'
import {CreateProject} from '../pages/overview/create-project/CreateProject'
import {Documentation} from '../pages/documentation/Documentation'

import { Changelog } from '../pages/changelog/ChangelogPage'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/overview' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />

        <Route path='overview' element={<Overview />} />
        <Route path='create-project2' element={<CreateProject2 />} />

        <Route path='create-project' element={<CreateProject />} />

        <Route path='project/:id/overview' element={<ProjectOverview />} />
        <Route path='project/:id/api-management' element={<ProjectApiManagement />} />
        <Route path='project/:id/usages' element={<ProjectUsagesAndHistory />} />
        <Route path='project/:id/pricing' element={<ProjectPricing />} />
        <Route path='project/:id/setting' element={<ProjectSetting />} />

        <Route path='document' element={<Documentation />} />
        <Route path='changelog' element={<Changelog />} />

        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'
import FirebaseAuthService from '../../firebase/FirebaseAuthService'
import firebase from '../../firebase/FirebaseConfig'
import {Container, Row, Col, Form, Button} from 'react-bootstrap'
import {useState} from 'react'
import {useUser} from './UserContext' // Import the useUser hook
import {Link} from 'react-router-dom'
import CryptoJS from 'crypto-js' // Import crypto-js for encryption
import {PulseLoader} from 'react-spinners'
import {useEffect} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const firestore = firebase.firestore()

export function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  // const {user, setUser} = useUser() // Access user and setUser from context
  const [loading, setLoading] = useState(false)

  const auth = getAuth()

  useEffect(() => {
    document.body.style.backgroundImage = `url(${toAbsoluteUrl('/media/login_page/bg.png')})`
    document.body.style.backgroundSize = 'cover'
    document.body.style.backgroundRepeat = 'no-repeat'

    // Cleanup function
    return () => {
      document.body.style.backgroundImage = ''
      document.body.style.backgroundSize = ''
      document.body.style.backgroundRepeat = ''
    }
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in successfully
        const user = userCredential.user
        console.log(user)
        // firestore
        //   .collection('user')
        //   .where('user_id', '==', user.uid)
        //   .get()
        //   .then((querySnapshot) => {
        //     querySnapshot.forEach((doc) => {
        //       // console.log(doc.id, " => ", doc.data());
        //       const data = doc.data()
        //       const encryptedUser_id = data.user_id
        //       const userInfo = {
        //         email: data.email,
        //         firstname: data.firstname,
        //         lastname: data.lastname,
        //         image_profile: data.image_profile,
        //         type: data.type,
        //         telephone: data?.telephone,
        //         jobPosition: data?.jobPosition,
        //         company: data?.company,
        //         address: data?.address,
        //         user_id: CryptoJS.AES.encrypt(encryptedUser_id, 'encryptionSecretKey').toString(),
        //       }
        //       setUser(userInfo);
        //       setLoading(false)
        //     })
        //   })
        //   .catch((error) => {
        //     // Handle login errors
        //     const errorMessage = error.message
        //     setError(errorMessage)
        //   })
        // Update the user data in the context
      })
      .catch((error) => {
        // Handle login errors
        const errorMessage = error.message
        setError(errorMessage)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <div className='d-flex flex-column flex-root' id='kt_app_root'>
      <div className='d-flex flex-column flex-column-fluid flex-lg-row'>
        <div className='d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10'>
          <div className='d-flex flex-center flex-lg-start flex-column'>
            <a href='#' className='mb-7'>
              <img alt='Logo' src={toAbsoluteUrl('/media/login_page/logo1.png')} style={{width: '300px'}} />
            </a>
            {/* <h2 className='text-white fw-normal m-0'>API Management for Malin-1</h2> */}
            <h2 className='text-white fw-normal m-0'>An online platform developed to provide API services for Malin1 users.</h2>
            <h2 className='text-white fw-normal m-0'>For requesting data and develop according to user requirement.</h2>

          </div>
        </div>
        <div className='d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20'>
          <div className='bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20'>
            <div className='d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20'>
              <Form onSubmit={handleSubmit} className='form w-100'>
                <div className='text-center mb-11'>
                  <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
                  <div className='text-gray-500 fw-semibold fs-6'>Your Malin-1 Account</div>
                </div>

                <div className='fv-row mb-8'>
                  <Form.Group controlId='email' className='mt-5'>
                    {/* <Form.Label>Email :</Form.Label> */}
                    <Form.Control
                      type='email'
                      placeholder='Email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      // onInvalid={(e) => e.target.setCustomValidity('Please enter email')}

                      required
                      className='form-control bg-transparent'
                    />
                  </Form.Group>
                </div>

                <div className='fv-row mb-3'>
                  <Form.Group controlId='password' className='mt-5'>
                    {/* <Form.Label>Password:</Form.Label> */}
                    <Form.Control
                      type='password'
                      placeholder='Password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      // onInvalid={(e) => e.target.setCustomValidity('Please enter password')}
                      required
                    />
                  </Form.Group>
                </div>

                {/* <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
                  <div></div>
                  <a
                    href='../../demo1/dist/authentication/layouts/creative/reset-password.html'
                    className='link-primary'
                  >
                    Forgot Password ?
                  </a>
                </div> */}

                <Button
                  variant='primary'
                  type='submit'
                  className='my-10'
                  style={{width: '100%'}}
                  disabled={loading} // Disable the button while loading
                >
                  {loading ? <PulseLoader color='white' size={10} /> : 'Login'}
                </Button>

                {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
                  Not a Member yet?
                  <a
                    href='../../demo1/dist/authentication/layouts/creative/sign-up.html'
                    className='link-primary'
                  >
                    {'   '} Sign up
                  </a>
                </div> */}
              </Form>
            </div>
            <div className='d-flex flex-stack px-lg-10'></div>
          </div>
        </div>
      </div>
    </div>

    // <Container>
    //   <Row className='d-flex justify-content-center' style={{fontSize: 30, fontWeight: 'bold'}}>
    //     Sign In
    //   </Row>

    //   <Row className='justify-content-center mt-5'>
    //     <Col md={12}>
    //       <Form
    //        onSubmit={handleSubmit}
    //       >
    //         <Form.Group controlId='email' className='mt-5'>
    //           <Form.Label>Email address:</Form.Label>
    //           <Form.Control
    //             type='email'
    //             placeholder='Enter email'
    //             value={email}
    //             onChange={(e) => setEmail(e.target.value)}
    //             onInvalid={(e) => e.target.setCustomValidity('Please enter email')}
    //             required
    //           />
    //         </Form.Group>
    //         <Form.Group controlId='password' className='mt-5'>
    //           <Form.Label>Password:</Form.Label>
    //           <Form.Control
    //             type='password'
    //             placeholder='Password'
    //             value={password}
    //             onChange={(e) => setPassword(e.target.value)}
    //             onInvalid={(e) => e.target.setCustomValidity('Please enter password')}
    //             required
    //           />
    //         </Form.Group>
    //         <div className='d-flex justify-content-end'>
    //           <Link to='/auth/forgot-password' className='link-primary'>
    //             Reset Password ?
    //           </Link>
    //         </div>
    // <Button
    //   variant='primary'
    //   type='submit'
    //   className='my-10'
    //   style={{width: '100%'}}
    //   disabled={loading} // Disable the button while loading
    // >
    //   {loading ? <PulseLoader color='white' size={10} /> : 'Login'}
    // </Button>
    //       </Form>
    //       {error && <p className='text-danger mt-3'>{error}</p>}
    //     </Col>
    //   </Row>
    // </Container>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Dropdown1} from '../../../../_metronic/partials'
import {useUser} from '../../../modules/newauth/UserContext'
import firebase from '../../../firebase/FirebaseConfig'
import {useParams} from 'react-router-dom'
import {useEffect} from 'react'
import axios from 'axios'
import {useState} from 'react'
import {format} from 'date-fns'

const APIActivitiesWidget = ({className}) => {
  const {user} = useUser()
  const db = firebase.firestore()
  const {id} = useParams()

  const [items, setItems] = useState([])

  useEffect(() => {
    async function fetchData() {
      try {
        db.collection('api')
          .doc(id)
          .get()
          .then(async (doc) => {
            if (doc.exists) {
              const timestampInSeconds = Math.floor(Date.now() / 1000)
              const timestampInSecondsToStop = timestampInSeconds - 3600

              const response = await axios.get(
                `https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1/history/lastest/10`,
                {
                  headers: {
                    Authorization: `Bearer ${doc.data().apiKey}`,
                    'X-API-SECRET': doc.data().apiSecret,
                    'Content-Type': 'application/json',
                  },
                }
              )

              const data = response.data.data

              let log = []
              for (let index = 0; index < data.length; index++) {
                const element = data[index]

                let timeformat = new Date(element.timestamp * 1000)
                let formattedDate = format(timeformat, 'HH:mm')

                let path = ''
                if (element.path.length > 30) {
                  path = element.path.substring(0, 30) + '...'
                } else {
                  path = element.path
                }

                const newItem = {
                  time: formattedDate,
                  content: path,
                  iconColor: 'text-primary',
                }

                log.push(newItem)
              }

              setItems(log)
            } else {
              console.log('No such document!')
            }
          })
          .catch((error) => {
            console.log('Error getting document:', error)
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData()
  }, [])

  return (
    <div className={`card ${className}`}>
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>API Activities</span>
          <span className='text-muted fw-semibold fs-7'>Last 10 activities</span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button>
          <Dropdown1 />
        </div>
      </div>
      <div className='card-body pt-5'>
        <div className='timeline-label'>
          {items.map((item, index) => (
            <div className='timeline-item' key={index}>
              <div className='timeline-label fw-bold text-gray-800 fs-6'>{item.time}</div>
              <div className='timeline-badge'>
                <i className={`fa fa-genderless ${item.iconColor} fs-1`}></i>
              </div>
              <div className='fw-normal timeline-content text-muted ps-3'>{item.content}</div>
            </div>
          ))}
        </div>
        {/* <div className='timeline-label'>
          <div className='timeline-item'>
            <div className='timeline-label fw-bold text-gray-800 fs-6'>08:42</div>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-warning fs-1'></i>
            </div>
            <div className='fw-mormal timeline-content text-muted ps-3'>
              Outlines keep you honest. And keep structure
            </div>
          </div>
          <div className='timeline-item'>
            <div className='timeline-label fw-bold text-gray-800 fs-6'>10:00</div>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-success fs-1'></i>
            </div>
            <div className='timeline-content d-flex'>
              <span className='fw-bold text-gray-800 ps-3'>AEOL meeting</span>
            </div>
          </div>
          <div className='timeline-item'>
            <div className='timeline-label fw-bold text-gray-800 fs-6'>14:37</div>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-danger fs-1'></i>
            </div>
            <div className='timeline-content fw-bold text-gray-800 ps-3'>
              Make deposit
              <a href='#' className='text-primary'>
                USD 700
              </a>
              . to ESL
            </div>
          </div>
          <div className='timeline-item'>
            <div className='timeline-label fw-bold text-gray-800 fs-6'>16:50</div>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-content fw-mormal text-muted ps-3'>
              Indulging in poorly driving and keep structure keep great
            </div>
          </div>
          <div className='timeline-item'>
            <div className='timeline-label fw-bold text-gray-800 fs-6'>21:03</div>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-danger fs-1'></i>
            </div>
            <div className='timeline-content fw-semibold text-gray-800 ps-3'>
              New order placed
              <a href='#' className='text-primary'>
                #XF-2356
              </a>
              .
            </div>
          </div>
          <div className='timeline-item'>
            <div className='timeline-label fw-bold text-gray-800 fs-6'>16:50</div>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-primary fs-1'></i>
            </div>
            <div className='timeline-content fw-mormal text-muted ps-3'>
              Indulging in poorly driving and keep structure keep great
            </div>
          </div>
          <div className='timeline-item'>
            <div className='timeline-label fw-bold text-gray-800 fs-6'>21:03</div>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-danger fs-1'></i>
            </div>
            <div className='timeline-content fw-semibold text-gray-800 ps-3'>
              New order placed
              <a href='#' className='text-primary'>
                #XF-2356
              </a>
              .
            </div>
          </div>
          <div className='timeline-item'>
            <div className='timeline-label fw-bold text-gray-800 fs-6'>10:30</div>
            <div className='timeline-badge'>
              <i className='fa fa-genderless text-success fs-1'></i>
            </div>
            <div className='timeline-content fw-mormal text-muted ps-3'>
              Finance KPI Mobile app launch preparion meeting
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export {APIActivitiesWidget}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {UsersList} from '../../../../app/modules/profile/components/UsersList'
import {useUser} from '../../../modules/newauth/UserContext'
import {useIcon} from '../../../modules/icon/IconContext'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRocket} from '@fortawesome/free-solid-svg-icons'

type Props = {
  icon: string
  badgeColor: string
  status: string
  statusColor: string
  title: string
  description: string
  date: string
  url: string
  budget: string
  progress: number
  detail_obj?: any
  icon_obj: any
}

const CardProject: FC<Props> = ({
  icon,
  badgeColor,
  status,
  statusColor,
  title,
  description,
  date,
  url,
  budget,
  progress,
  detail_obj,
  icon_obj,
}) => {
  const {user} = useUser()

  const users1 = [
    // {name: 'Emma Smith', avatar: '/media/avatars/300-6.jpg'},
    // {name: 'Rudy Stone', avatar: '/media/avatars/300-1.jpg'},
    {name: user.email, initials: user.email.charAt(0), color: 'success'},
  ]

  const {iconMapping} = useIcon()

  let iconMap
  if (icon_obj !== undefined) {
    const iconName = icon_obj.iconValue
    iconMap = iconMapping[iconName]
  }

  return (
    <Link
      to={url}
      className='card border border-2 border-gray-300 border-hover'
      style={{boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'}}
    >
      <div className='card-header border-0 pt-9 ribbon ribbon-top ribbon-vertical'>
        {status == 'Production' ? (
          <div className='ribbon-label' style={{minHeight: '40px', backgroundColor: '#dc3545'}}>
            <FontAwesomeIcon icon={faRocket} className='fs-2 text-white' />
          </div>
        ) : (
          ''
        )}

        <div className='card-title m-1'>
          <div className='symbol symbol-50px w-50px'>
            <div className='symbol symbol-50px'>
              <div
                className='symbol-label'
                style={{
                  backgroundColor: icon_obj?.colorBackgroundIcon || '#009ef7',
                  color: icon_obj?.colorBackgroundIcon || '#009ef7',
                }}
              >
                {icon_obj === undefined ? (
                  <FontAwesomeIcon icon={faRocket} className='fs-1' style={{color: 'white'}} />
                ) : (
                  <FontAwesomeIcon
                    icon={iconMap}
                    className='fs-1'
                    style={{color: icon_obj?.colorIcon}}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='card-toolbar'>
          <span className={`badge badge-light-${badgeColor} fw-bolder me-auto px-4 py-3`}>
            {status}
          </span>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='fs-3 fw-bolder text-dark'>{title}</div>

        <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{description}</p>

        <div className='d-flex flex-wrap mb-2'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>
              {capitalizeFirstLetter(detail_obj.planType)}
            </div>
            <div className='fw-bold text-gray-400'>Plan type</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
            <div className='fs-6 text-gray-800 fw-bolder'>
              {detail_obj.usage.currentUsage.toLocaleString()} /{' '}
              {detail_obj.usage.usageLimit.toLocaleString()}
            </div>
            <div className='fw-bold text-gray-400'>Transaction</div>
          </div>
        </div>

        <div
          className='h-4px w-100 bg-light mb-5'
          data-bs-toggle='tooltip'
          title='This project completed'
        >
          <div
            className={`rounded h-3px`}
            role='progressbar'
            style={{
              width: `${progress}%`,
              ...(detail_obj.planType === 'free'
                ? {
                    // background: 'linear-gradient(45deg, #92FE9D, #00C9FF)',
                    background: '#E0E0E0',
                  }
                : detail_obj.planType === 'standard'
                ? {
                    // background: 'linear-gradient(45deg, #00C9FF, #3faefb)',
                    background: '#00C9FF',
                  }
                : detail_obj.planType === 'pro'
                ? {
                    // background: 'linear-gradient(45deg, #00C9FF, #3f5efb)',
                    background: '#3F5EFB',
                  }
                : {
                    background: 'linear-gradient(45deg, #3f5efb, #000146)',
                  }),
            }}
          ></div>
        </div>

        <UsersList users={users1} />
      </div>
    </Link>
  )
}

export {CardProject}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

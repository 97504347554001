/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Dropdown1} from '../../../../_metronic/partials'
import {KTIcon} from '../../../../_metronic/helpers'

type Props = {
  className: string
  color: string
  currentUsage: string
  usageLimit: string
  deviceCount: string
  parameterCount: string
  parameterValueCount: string
}

const APIUsagesWidget: React.FC<Props> = ({
  className,
  color,
  currentUsage,
  usageLimit,
  deviceCount,
  parameterCount,
  parameterValueCount,
}) => {
  return (
    <div className={`card ${className}`}>
      <div className='card-body p-0'>
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>API request method</h3>
          </div>
          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>You Request</span>
            <span className='fw-bold fs-2x pt-1'>
              {currentUsage.toLocaleString()} / {usageLimit.toLocaleString()}
            </span>
          </div>
        </div>
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='compass' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  /v1/devices
                </a>
                <div className='text-gray-400 fw-semibold fs-7'>Device details</div>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>{deviceCount}</div>
                <KTIcon iconName='arrow-up' className='fs-5 text-success ms-1' />
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center mb-6'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='category' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  /v1/parameters
                </a>
                <div className='text-gray-400 fw-semibold fs-7'>Parameter details</div>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>{parameterCount}</div>
                <KTIcon iconName='arrow-down' className='fs-5 text-danger ms-1' />
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='phone' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                  /v1/parameter-values
                </a>
                <div className='text-gray-400 fw-semibold fs-7'>Parameter values</div>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-800 pe-1'>{parameterValueCount}</div>
                <KTIcon iconName='arrow-up' className='fs-5 text-success ms-1' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {APIUsagesWidget}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {Link, useParams} from 'react-router-dom'

type Props = {
  className: string
}

const ExternalLinkWidget = ({className}: Props) => {
  // Move useParams inside the component function
  const {id} = useParams()
  const rows: Array<{description: string; link: string}> = [
    {description: 'How to use API', link: '/document'},
    {description: 'API Documentation', link: '/document'},
    {description: 'Upgrade Plan', link: `/project/${id}/pricing`},
  ]

  return (
    <div
      className={`card card-flush ${className}`}
      style={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      }}
    >
      <div className='card-header pt-5'>
        <h3 className='card-title text-gray-800 fw-bold'>External Links</h3>
        <div className='card-toolbar'></div>
      </div>
      <div className='card-body pt-5'>
        {rows.map((row, index) => (
          <Fragment key={`lw26-rows-${index}`}>
            <div className='d-flex flex-stack'>
              <Link to={row.link} className='text-primary fw-semibold fs-6 me-2'>
                {row.description}
              </Link>
              <button
                type='button'
                className='btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end'
              >
                <KTIcon iconName='exit-right-corner' className='fs-2' />
              </button>
            </div>
            {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
          </Fragment>
        ))}
      </div>
    </div>
  )
}

export {ExternalLinkWidget}

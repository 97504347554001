import {PageTitle} from '../../../_metronic/layout/core'
import {useUser} from '../../modules/newauth/UserContext'
import firebase from '../../firebase/FirebaseConfig'
import {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {KTIcon} from '../../../_metronic/helpers'

const ChangelogPage = () => {
  const {user} = useUser()
  const db = firebase.firestore()
  const {id} = useParams()

  return (
    <>
      <div
        className='card card-custom'
        style={{boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px'}}
      >
        <div className='card-body'>
          <div className='d-flex flex-wrap flex-stack mb-6'>
            <h1 id='device-endpoint-section' className='fw-bolder'>
              Changelog
            </h1>
          </div>

          <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
            {/* <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_2_item_3'
              >
                <span className='accordion-icon'>
                  <KTIcon iconName={'abstract-10'} className={`fs-3 ms-n1`} />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>Accordion Item #3</h3>
              </div>
              <div
                id='kt_accordion_2_item_3'
                className='collapse fs-6 ps-10'
                data-bs-parent='#kt_accordion_2'
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                unknown printer took a galley of type and scrambled it to make a type specimen book.
                It has survived not only five centuries, but also the leap into electronic
                typesetting, remaining essentially unchanged.
              </div>
            </div> */}

            {/* <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_item_2'
              >
                <span className='accordion-icon'>
                  <KTIcon iconName={'abstract-10'} className={`fs-3 ms-n1`} />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>
                  Malin1 Developers v1.1.0 - April 1, 2024
                </h3>
              </div>
              <div
                id='kt_accordion_item_2'
                className='collapse fs-6 ps-10'
                data-bs-parent='#kt_accordion_2'
              >
                <div className='fw-bold'>New:</div>
                <div className='d-flex flex-column'>
                  <li className='d-flex align-items-center py-2'>
                    <span className='bullet me-5'></span>React-select plugin with custom style.
                  </li>
                </div>
                <div className='fw-bold'>Updates:</div>
                <div className='d-flex flex-column'>
                  <li className='d-flex align-items-center py-2'>
                    <span className='bullet me-5'></span>Move toolbar to page lavel.
                  </li>
                </div>
                <div className='fw-bold'>Fixes:</div>
                <div className='d-flex flex-column'>
                  <li className='d-flex align-items-center py-2'>
                    <span className='bullet me-5'></span>Fixed absolute url double slash in media
                    paths.
                  </li>
                </div>
              </div>
            </div> */}

            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex collapsed'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_item_2'
              >
                <span className='accordion-icon'>
                  <KTIcon iconName={'abstract-10'} className={`fs-3 ms-n1`} />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>
                  Malin1 Developers v1.1.0 - April 2, 2024
                </h3>
              </div>
              <div
                id='kt_accordion_item_2'
                className='collapse fs-6 ps-10'
                data-bs-parent='#kt_accordion_2'
              >
                <div className='fw-bold'>New:</div>
                <div className='d-flex flex-column'>
                  <li className='d-flex align-items-center py-2'>
                    <span className='bullet me-5'></span>Import parameters to the API.
                  </li>
                </div>

                <div className='fw-bold'>Fixes:</div>
                <div className='d-flex flex-column'>
                  <li className='d-flex align-items-center py-2'>
                    <span className='bullet me-5'></span>Fixed API authentication.
                  </li>
                </div>
              </div>
            </div>

            <div className='mb-5'>
              <div
                className='accordion-header py-3 d-flex'
                data-bs-toggle='collapse'
                data-bs-target='#kt_accordion_item_1'
              >
                <span className='accordion-icon'>
                  <KTIcon iconName={'abstract-10'} className={`fs-3 ms-n1`} />
                </span>
                <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>
                  Malin1 Developers v1.0.0 - March 7, 2024
                </h3>
              </div>
              <div
                id='kt_accordion_item_1'
                className='fs-6 collapse show ps-10'
                data-bs-parent='#kt_accordion_2'
              >
                <div className='d-flex flex-column'>
                  <li className='d-flex align-items-center py-2'>
                    <span className='bullet me-5'></span>Initial release
                  </li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Changelog = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Changelog'}</PageTitle>
      <ChangelogPage />
    </>
  )
}

export {Changelog}

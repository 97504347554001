import {PageTitle} from '../../../../_metronic/layout/core'
// import {Card2} from '../../../_metronic/partials/content/cards/Card2'
// import {CardProject} from './widget/CardProject'
import {useUser} from '../../../modules/newauth/UserContext'
import firebase from '../../../firebase/FirebaseConfig'
import {useEffect, useState} from 'react'

import {useNavigate} from 'react-router-dom'

import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCloudSun,
  faHouseSignal,
  faWheatAwn,
  faBuilding,
  faSolarPanel,
  faLightbulb,
  faPerson,
  faLayerGroup,
} from '@fortawesome/free-solid-svg-icons'

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import CryptoJS from 'crypto-js'

const CreateProjectPage = () => {
  const db = firebase.firestore()
  const {user} = useUser()
  const MySwal = withReactContent(Swal)
  let navigate = useNavigate()

  const RegisterSchema = Yup.object().shape({
    project_name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    shortened_description: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  })

  async function createProjectSubmit(values) {
    MySwal.fire({
      title: 'Please check project detail?',
      html: `<h4>Project name : ${values.project_name} </h4> 
          <h4>Project description : ${values.shortened_description} </h4> 
          <h4>Project type : ${values.project_type} </h4> 
          <h4>Project category : ${values.project_category} </h4>`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#009ef7',
      cancelButtonColor: '#f1416c',
      confirmButtonText: 'Create a proejct',
    }).then((result) => {
      if (result.isConfirmed) {
        createProjectConfirmSubmit(values)
      }
    })
  }

  // Function to generate a random string of a given length
  function generateRandomString(length) {
    // Generate random bytes and convert them to a hexadecimal string
    const randomBytes = CryptoJS.lib.WordArray.random(length / 2)
    return randomBytes.toString(CryptoJS.enc.Hex)
  }

  // Generate API Key and Secret with specific lengths
  function generateApiKeyAndSecret(keyLength = 256, secretLength = 256) {
    const apiKey = generateRandomString(keyLength)
    const apiSecret = generateRandomString(secretLength)

    return {apiKey, apiSecret}
  }

  async function createProjectConfirmSubmit(values) {
    const MySwal2 = withReactContent(Swal)
    console.log(values)

    MySwal2.fire({
      title: 'Wait a moment, the project is being created!',
      html: 'Initializing...',
      showConfirmButton: false, // ไม่แสดงปุ่มยืนยัน
      // allowOutsideClick: false,
      didOpen: () => {
        MySwal2.showLoading()
      },
    })

    await new Promise((resolve) => setTimeout(resolve, 2000))
    MySwal2.update({
      html: 'Generating API Key and Secret...',
    })
    MySwal2.showLoading()

    const {apiKey, apiSecret} = generateApiKeyAndSecret()
    // console.log(`API Key: ${apiKey}`)
    // console.log(`API Secret: ${apiSecret}`)

    await new Promise((resolve) => setTimeout(resolve, 2000))
    MySwal2.update({
      html: 'Generating API Key and Secret... Done!',
    })
    MySwal2.showLoading()

    await new Promise((resolve) => setTimeout(resolve, 2000))
    MySwal2.update({
      html: 'Createing project...',
    })
    MySwal2.showLoading()

    var nowForStartBill = new Date()
    nowForStartBill.setDate(1)
    nowForStartBill.setHours(0, 0, 0, 0)
    const timestampForStartBill = Math.floor(nowForStartBill.getTime() / 1000)

    var nowForStopBill = new Date()
    if (nowForStopBill.getMonth() === 11) {
      nowForStopBill.setFullYear(nowForStopBill.getFullYear() + 1, 0, 1)
    } else {
      nowForStopBill.setMonth(nowForStopBill.getMonth() + 1, 1)
    }
    nowForStopBill.setHours(0, 0, 0, 0)
    const timestampForStopBill = Math.floor(nowForStopBill.getTime() / 1000)

    // Add a new document with a generated id.
    var timestampInSeconds = Math.floor(Date.now() / 1000)
    await db
      .collection('api')
      .add({
        accessControl: {},
        apiDetail: {
          endPoint: '',
          method: '',
          version: '',
        },
        apiKey: apiKey,
        apiSecret: apiSecret,
        billingInfo: {
          billingCycleEndDate: timestampForStopBill,
          billingCycleStartDate: timestampForStartBill,
          lastResetDate: timestampInSeconds,
        },
        cretedOn: timestampInSeconds,
        description: values.shortened_description,
        environment: 'development',
        lastUpdated: timestampInSeconds,
        owner: user.user_id,
        planType: 'free',
        projectId: '',
        projectName: values.project_name,
        projectType: values.project_type,
        projectCategory: values.project_category,
        projectImageProfile: '',
        usage: {
          currentUsage: 0,
          exceedLimit: 0,
          startUsing: timestampInSeconds,
          usageLimit: 1000,
        },
        usageStatistic: {},
      })
      .then((docRef) => {
        console.log('Document written with ID: ', docRef.id)
      })
      .catch((error) => {
        console.error('Error adding document: ', error)
      })

    await new Promise((resolve) => setTimeout(resolve, 2000))
    MySwal2.update({
      html: 'Createing project... Done!',
    })
    MySwal2.showLoading()

    await new Promise((resolve) => setTimeout(resolve, 2000))

    MySwal2.fire({
      title: 'Create project complete!',
      icon: 'success',
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/overview')
      }
    })
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-body'>
          <div className='w-100'>
            <div className='pb-3 pb-lg-3'>
              <h3 className='fw-bolder d-flex align-items-center text-dark'>
                Choose Project Types
              </h3>
            </div>

            <div className='fv-row'>
              <Formik
                validationSchema={RegisterSchema}
                initialValues={{
                  project_name: '',
                  shortened_description: '',
                  project_type: 'personal',
                  project_category: 'general_uses',
                }}
                onSubmit={(values) => {
                  //handle form submit
                  createProjectSubmit(values)
                }}
              >
                {({values, errors, touched}) => (
                  <Form>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <Field
                          type='radio'
                          className='btn-check'
                          name='project_type'
                          value='personal'
                          id='create_project_type_personal'
                        />
                        <label
                          className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                          htmlFor='create_project_type_personal'
                          style={
                            values.project_type === 'personal' ? {backgroundColor: '#fafafa'} : {}
                          }
                        >
                          <FontAwesomeIcon
                            icon={faPerson}
                            className='fs-3x me-5'
                            style={
                              values.project_type === 'personal'
                                ? {color: '#009ef7'}
                                : {color: '#b0b0c2'}
                            }
                          />

                          <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                              Personal Project
                            </span>
                            <span className='text-gray-400 fw-bold fs-6'>
                              If you need more info, please check it out
                            </span>
                          </span>
                        </label>
                      </div>

                      <div className='col-lg-6'>
                        <Field
                          type='radio'
                          className='btn-check'
                          name='project_type'
                          value='application_development'
                          id='create_project_type_application_development'
                        />
                        <label
                          className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center'
                          htmlFor='create_project_type_application_development'
                          style={
                            values.project_type === 'application_development'
                              ? {backgroundColor: '#fafafa'}
                              : {}
                          }
                        >
                          <FontAwesomeIcon
                            icon={faLayerGroup}
                            className='fs-3x me-5'
                            style={
                              values.project_type === 'application_development'
                                ? {color: '#009ef7'}
                                : {color: '#b0b0c2'}
                            }
                          />

                          <span className='d-block fw-bold text-start'>
                            <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                              Application Development Project
                            </span>
                            <span className='text-gray-400 fw-bold fs-6'>
                              Software solutions targeting wide audiences or markets.
                            </span>
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className='mb-15'>
                      <div className='pb-3 pb-lg-3'>
                        <h3 className='fw-bolder d-flex align-items-center text-dark'>
                          Project Details
                        </h3>
                      </div>

                      <div className='fv-row mb-5'>
                        <label className='form-label required' htmlFor='project_name'>
                          Project Name
                        </label>
                        <Field
                          name='project_name'
                          type='text'
                          id='project_name'
                          className='form-control form-control-lg form-control-solid'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='project_name' />
                        </div>
                      </div>

                      <div className='fv-row mb-5'>
                        <label
                          className='d-flex align-items-center form-label'
                          htmlFor='shortened_description'
                        >
                          <span className='required'>Shortened Description</span>
                        </label>

                        <Field
                          name='shortened_description'
                          type='text'
                          id='shortened_description'
                          className='form-control form-control-lg form-control-solid'
                        />
                        <div className='text-danger mt-2'>
                          <ErrorMessage name='shortened_description' />
                        </div>

                        <div className='form-text'>
                          Customers will see this shortened version of your statement descriptor
                        </div>
                      </div>
                    </div>

                    <div className='fv-row mb-10'>
                      <div className='pb-3 pb-lg-3'>
                        <h3 className='fw-bolder d-flex align-items-center text-dark'>
                          Choose Project Categories
                        </h3>
                      </div>

                      <div className='fv-row'>
                        <div className='row'>
                          <div className='col-lg-4'>
                            <Field
                              type='radio'
                              className='btn-check'
                              name='project_category'
                              value='home_automation_system'
                              id='create_project_category_home'
                            />
                            <label
                              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                              htmlFor='create_project_category_home'
                              style={
                                values.project_category === 'home_automation_system'
                                  ? {backgroundColor: '#fafafa'}
                                  : {}
                              }
                            >
                              <FontAwesomeIcon
                                icon={faHouseSignal}
                                className='fs-3x me-5'
                                style={
                                  values.project_category === 'home_automation_system'
                                    ? {color: '#009ef7'}
                                    : {color: '#b0b0c2'}
                                }
                              />

                              <span className='d-block fw-bold text-start'>
                                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                  Home Automation System
                                </span>
                                <span className='text-gray-400 fw-bold fs-6'>
                                  Smart system controlling home devices for convenience, efficiency.
                                </span>
                              </span>
                            </label>
                          </div>

                          <div className='col-lg-4'>
                            <Field
                              type='radio'
                              className='btn-check'
                              name='project_category'
                              value='weather_reporting_system'
                              id='create_project_category_weather'
                            />
                            <label
                              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                              htmlFor='create_project_category_weather'
                              style={
                                values.project_category === 'weather_reporting_system'
                                  ? {backgroundColor: '#fafafa'}
                                  : {}
                              }
                            >
                              <FontAwesomeIcon
                                icon={faCloudSun}
                                className='fs-3x me-5'
                                style={
                                  values.project_category === 'weather_reporting_system'
                                    ? {color: '#009ef7'}
                                    : {color: '#b0b0c2'}
                                }
                              />

                              <span className='d-block fw-bold text-start'>
                                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                  Weather Reporting System
                                </span>
                                <span className='text-gray-400 fw-bold fs-6'>
                                  Automated system providing real-time weather updates and
                                  forecasts.
                                </span>
                              </span>
                            </label>
                          </div>

                          <div className='col-lg-4'>
                            <Field
                              type='radio'
                              className='btn-check'
                              name='project_category'
                              value='smart_agriculture_system'
                              id='create_project_category_agriculture'
                            />
                            <label
                              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                              htmlFor='create_project_category_agriculture'
                              style={
                                values.project_category === 'smart_agriculture_system'
                                  ? {backgroundColor: '#fafafa'}
                                  : {}
                              }
                            >
                              <FontAwesomeIcon
                                icon={faWheatAwn}
                                className='fs-3x me-5'
                                style={
                                  values.project_category === 'smart_agriculture_system'
                                    ? {color: '#009ef7'}
                                    : {color: '#b0b0c2'}
                                }
                              />

                              <span className='d-block fw-bold text-start'>
                                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                  Smart Agriculture System
                                </span>
                                <span className='text-gray-400 fw-bold fs-6'>
                                  Technology-driven system enhancing farming efficiency and
                                  productivity.
                                </span>
                              </span>
                            </label>
                          </div>

                          <div className='col-lg-4'>
                            <Field
                              type='radio'
                              className='btn-check'
                              name='project_category'
                              value='smart_building_system'
                              id='create_project_category_building'
                            />
                            <label
                              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                              htmlFor='create_project_category_building'
                              style={
                                values.project_category === 'smart_building_system'
                                  ? {backgroundColor: '#fafafa'}
                                  : {}
                              }
                            >
                              <FontAwesomeIcon
                                icon={faBuilding}
                                className='fs-3x me-5'
                                style={
                                  values.project_category === 'smart_building_system'
                                    ? {color: '#009ef7'}
                                    : {color: '#b0b0c2'}
                                }
                              />

                              <span className='d-block fw-bold text-start'>
                                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                  Smart Building System
                                </span>
                                <span className='text-gray-400 fw-bold fs-6'>
                                  Intelligent system optimizing building operations for energy
                                  efficiency.
                                </span>
                              </span>
                            </label>
                          </div>

                          <div className='col-lg-4'>
                            <Field
                              type='radio'
                              className='btn-check'
                              name='project_category'
                              value='smart_enerygy_system'
                              id='create_project_category_energy'
                            />
                            <label
                              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                              htmlFor='create_project_category_energy'
                              style={
                                values.project_category === 'smart_enerygy_system'
                                  ? {backgroundColor: '#fafafa'}
                                  : {}
                              }
                            >
                              <FontAwesomeIcon
                                icon={faSolarPanel}
                                className='fs-3x me-5'
                                style={
                                  values.project_category === 'smart_enerygy_system'
                                    ? {color: '#009ef7'}
                                    : {color: '#b0b0c2'}
                                }
                              />

                              <span className='d-block fw-bold text-start'>
                                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                  Smart Energy System
                                </span>
                                <span className='text-gray-400 fw-bold fs-6'>
                                  Intelligent grid optimizing energy use for sustainability,
                                  savings.
                                </span>
                              </span>
                            </label>
                          </div>

                          <div className='col-lg-4'>
                            <Field
                              type='radio'
                              className='btn-check'
                              name='project_category'
                              value='general_uses'
                              id='create_project_category_general_uses'
                            />
                            <label
                              className='btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10'
                              htmlFor='create_project_category_general_uses'
                              style={
                                values.project_category === 'general_uses'
                                  ? {backgroundColor: '#fafafa'}
                                  : {}
                              }
                            >
                              <FontAwesomeIcon
                                icon={faLightbulb}
                                className='fs-3x me-5'
                                style={
                                  values.project_category === 'general_uses'
                                    ? {color: '#009ef7'}
                                    : {color: '#b0b0c2'}
                                }
                              />

                              <span className='d-block fw-bold text-start'>
                                <span className='text-dark fw-bolder d-block fs-4 mb-2'>
                                  General Uses
                                </span>
                                <span className='text-gray-400 fw-bold fs-6'>...</span>
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className='text-danger mt-2'></div>
                    </div>

                    <button type='submit' className='btn btn-primary' style={{width: '100%'}}>
                      Create project
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CreateProject = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{'Create new project'}</PageTitle>
      <CreateProjectPage />
    </>
  )
}

export {CreateProject}

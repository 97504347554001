/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState, useCallback, useMemo} from 'react'
import ApexCharts from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../_metronic/partials'

import axios from 'axios'
import {useUser} from '../../../modules/newauth/UserContext'
import firebase from '../../../firebase/FirebaseConfig'
import {useParams} from 'react-router-dom'
import {format} from 'date-fns'

const ChartLineUsagesWidget = React.memo(({className, dataChart, chartRange}) => {
  const chartRef = useRef(null)
  const {mode} = useThemeMode()

  const {user} = useUser()
  const db = firebase.firestore()
  const {id} = useParams()

  const [dataChartTime, setDataChartTime] = useState([])
  const [dataChartValue, setDataChartValue] = useState([])

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }
    const chartOptions = getChartOptions(dataChartTime, dataChartValue) // Adjust accordingly if you're passing more state variables
    const chart = new ApexCharts(chartRef.current, chartOptions)
    chart.render()
    return chart
  }

  useEffect(() => {
    const fetchData = async () => {
      const data = dataChart
      const array = await findNextDivisibleByFiveMinuteTimestamp(data, chartRange)

      let log_time = []
      for (let index = 0; index < array.length; index++) {
        const element = array[index]
        var date = new Date(element.startTimeStamp * 1000 + 25200000)
        var dateString = date.toISOString()
        log_time.push(dateString)
      }

      setDataChartTime(log_time)
      setDataChartValue(array.map((item) => item.transaction_count))
    }

    fetchData()
  }, [dataChart])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, dataChartValue])

  return (
    <div
      className={`card col-xl-4 h-md-100`}
      style={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        // border: '1px solid blue',
        flex: '1',
        minWidth: '300px',
        maxWidth: '1fr',
      }}
    >
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>API Requests</span>

          {/* <span className='text-muted fw-semibold fs-7'>In last 2 hour</span> */}
        </h3>
      </div>
      <div
        className='card-body'
        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      >
        <div ref={chartRef} id='kt_charts_widget_3_chart'></div>
      </div>
    </div>
  )
})

export {ChartLineUsagesWidget}

function getChartOptions(dataChartTime, dataChartValue) {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')

  return {
    series: [
      {
        name: 'series1',
        data: dataChartValue,
      },
    ],
    chart: {
      width: '100%',
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'datetime',
      categories: dataChartTime,
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
    colors: ['#7239ea'],
  }
}

async function findNextDivisibleByFiveMinuteTimestamp(data, chartRange) {
  let now = new Date() // รับเวลาปัจจุบัน
  let minutes = now.getMinutes() // รับนาทีปัจจุบัน
  let remainder = 5 - (minutes % 5) // คำนวณเวลาที่ต้องเพิ่มเพื่อให้นาทีถัดไปหารด้วย 5 ลงตัว
  if (remainder !== 5) {
    // ถ้านาทีปัจจุบันไม่หารด้วย 5 ลงตัว
    now.setMinutes(minutes + remainder) // เซ็ตเวลาให้เป็นนาทีถัดไปที่หารด้วย 5 ลงตัว
    now.setSeconds(0) // ตั้งค่าวินาทีเป็น 0 เพื่อรับเวลาที่แน่นอน
    now.setMilliseconds(0) // ตั้งค่ามิลลิวินาทีเป็น 0
  }

  const startTimeStamp = Math.floor(now.getTime() / 1000) // คืนค่า timestamp ในหน่วยวินาที

  let array = []

  for (let index = 0; index < chartRange * 12; index++) {
    array.push({
      startTimeStamp: startTimeStamp - 300 * index,
      endTimeStamp: startTimeStamp - 300 * (index + 1) + 1,
      transaction_count: 0,
    })
  }

  for (let index = 0; index < data.length; index++) {
    for (let index2 = 0; index2 < array.length; index2++) {
      if (
        data[index].timestamp >= array[index2].endTimeStamp &&
        data[index].timestamp <= array[index2].startTimeStamp
      ) {
        array[index2].transaction_count =
          array[index2].transaction_count + parseInt(data[index].transaction_count)
      }
    }
  }

  array.reverse()

  return array
}

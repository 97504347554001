/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'

// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import {faFolderOpen} from '@fortawesome/free-solid-svg-icons'
import ProgressBar from 'react-bootstrap/ProgressBar'

type Props = {
  className: string
  color: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  currentUsage: number
  usageLimit: number
}

const UsageTransactionWidget: React.FC<Props> = ({
  className,
  color,
  title,
  titleColor,
  description,
  descriptionColor,
  currentUsage,
  usageLimit,
}) => {
  return (
    <div
      className={`card hoverable ${className}`}
      style={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
      }}
    >
      <div className='card-body'>
        <KTIcon iconName={'arrow-zigzag'} className={`text-black fs-3x ms-n1`} />

        <div className={`text-black fw-bold fs-1 mb-2 mt-5`}>{title}</div>

        <div className={`fw-semibold text-black`}>{description}</div>

        <br></br>

        <ProgressBar
          style={{height: '10%', fontSize: '1.1rem', fontWeight: 'bold'}}
          now={(currentUsage * 100) / usageLimit}
          label={`${(currentUsage * 100) / usageLimit}%`}
        />
      </div>
    </div>
  )
}

export {UsageTransactionWidget}

import React, { createContext, useContext, useState, useCallback } from 'react';
import firebase from '../../firebase/FirebaseConfig'
import { useEffect } from 'react';

const ProjectContext = createContext();
const firestore = firebase.firestore()


export function useProject() {
    return useContext(ProjectContext);
}

export function ProjectProvider({ children }) {
    const [project, setProject] = useState(null);

    const loadProject = useCallback(async (projectId) => {

        firestore.collection("api").doc(projectId).get().then((doc) => {
            if (doc.exists) {
                setProject({
                    id: doc.id,
                    ...doc.data()
                });
            } else {
                console.log("No such document!");
                setProject({})
            }
        }).catch((error) => {
            setProject({})
            console.log("Error getting document:", error);
        });

    }, []);


    return (
        <ProjectContext.Provider value={{ project, loadProject }}>
            {children}
        </ProjectContext.Provider>
    );
}

async function getProjectDataFromAPI(projectId) {
    return { id: projectId, name: 'Project Name' };
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFolderOpen} from '@fortawesome/free-solid-svg-icons'
type Props = {
  className: string
  title: string
  description: string
  projectData: any
}

const ProjectNameWidget: React.FC<Props> = ({className, title, description, projectData}) => {
  let colorBg1 = '#00C9FF'
  let colorBg2 = '#92FE9D'
  let fontColor = 'white'

  // if (projectData.planType === 'standard') {
  //   colorBg1 = '#00C9FF'
  //   colorBg2 = 'rgba(63,174,251,1)'
  //   fontColor = 'white'
  // } else if (projectData.planType === 'pro') {
  //   colorBg1 = '#3f5efb'
  //   colorBg2 = '#00C9FF'
  //   fontColor = 'white'
  // } else if (projectData.planType === 'custom') {
  colorBg1 = '#1f2fa0'
  colorBg2 = '#3f5efb'
  fontColor = 'white'
  // }

  // #000146

  return (
    <div
      className={`card hoverable ${className}`}
      style={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        background: `linear-gradient(317deg, #3f5efb 0%, #00C9FF 100%)`,
      }}
    >
      <div className='card-body'>
        <KTIcon iconName={'underlining'} className={`text-white fs-3x ms-n1`} />

        <div className={`fw-bold fs-2 mb-2 mt-5`} style={{color: fontColor}}>
          {title}
        </div>

        <div className={`fw-semibold fs-4`} style={{color: fontColor}}>
          {capitalizeFirstLetter(projectData?.planType)} Plan
        </div>
      </div>
    </div>
  )
}

export {ProjectNameWidget}

function capitalizeFirstLetter(string) {
  if (string === undefined || string === null || string.trim() === '') {
    return string
  }
  const str = String(string)
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const DomainNameSection = () => {
  return (
    <>
      <h1 id='domain-name-section' className='fw-bolder'>
        Domain name
      </h1>
      <div className='text-gray-700 fs-5 my-5'>
        In an API, the domain name can vary depending on the endpoint being accessed. It is crucial
        to ensure the correct domain name is used for each specific endpoint to prevent connection
        errors.
      </div>

      <table className='table gs-7 gy-7 gx-7'>
        <thead>
          <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
            <th style={{width: '40%'}}>Domain name</th>
            <th style={{width: '60%'}}>Endpoint</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>https://asia-southeast1-dygistechplatform.cloudfunctions.net/api/v1</td>
            <td>
              <div className='d-flex flex-column'>
                <li className='d-flex align-items-center py-2'>
                  <span className='bullet me-5'></span>Device
                </li>
                <li className='d-flex align-items-center py-2'>
                  <span className='bullet me-5'></span>Parameter
                </li>
                <li className='d-flex align-items-center py-2'>
                  <span className='bullet me-5'></span>Parameter Value
                </li>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export {DomainNameSection}

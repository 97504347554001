const RateLimitsSection = () => {
  return (
    <>
      <h1 id='rate-limits-section' className='fw-bolder'>
        Rate limits
      </h1>
      <div className='text-gray-700 fs-5 my-5'>
        API applies the following rate limits for each API function (endpoint).
      </div>

      <div className='alert alert-danger d-flex align-items-center p-5 mb-10'>
        <span className='svg-icon svg-icon-2hx svg-icon-warning me-3'>...</span>
        <div className='d-flex flex-column'>
          <h5 className='mb-1'>Do not submit requests that exceed the rate limit.</h5>
          <span>
            If you submit requests that exceed the rate limit, you will receive an error message.
            Exceeding this limit may also result in additional charges.
          </span>
        </div>
      </div>

      <table className='table gs-7 gy-7 gx-7'>
        <thead>
          <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
            <th style={{width: '40%'}}>Endpoint</th>
            <th style={{width: '60%'}}>Rate limit</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className='d-flex flex-column'>
                <li className='d-flex align-items-center py-2'>
                  <span className='bullet me-5'></span>Device
                </li>
                <li className='d-flex align-items-center py-2'>
                  <span className='bullet me-5'></span>Parameter
                </li>
                <li className='d-flex align-items-center py-2'>
                  <span className='bullet me-5'></span>Parameter Value
                </li>
              </div>
            </td>
            <td>1,000 requests per minute</td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export {RateLimitsSection}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../_metronic/helpers'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCloudArrowUp} from '@fortawesome/free-solid-svg-icons'

const TotalRequestWidget = ({svgIcon, iconColor, projectData}) => {
  let colorBg2 = '#92FE9D'
  let colorBg1 = '#61ECBE'

  let fontColor = 'white'

  // if (projectData.planType === 'standard') {
  //   colorBg2 = '#15C0FE'
  //   colorBg1 = '#00C9FF'
  //   fontColor = 'white'
  // } else if (projectData.planType === 'pro') {
  //   colorBg2 = '#00C9FF'
  //   colorBg1 = '#15A5FE'
  //   fontColor = 'white'
  // } else if (projectData.planType === 'custom') {
  colorBg2 = '#3f5efb'
  colorBg1 = '#2A3FBF'
  fontColor = 'white'
  // }

  return (
    <div
      className={`card hoverable card-xl-stretch mb-xl-8`}
      style={{
        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
        // background: `linear-gradient(317deg, ${colorBg1} 0%, ${colorBg2} 100%)`,
        backgroundColor: '#00C9FF',
      }}
    >
      <div className='card-body'>
        <FontAwesomeIcon icon={faCloudArrowUp} className='fs-2x ms-n1' style={{color: 'white'}} />

        <div className={`text-white fw-bold fs-2 mb-2 mt-5`}>
          {projectData?.usage?.currentUsage.toLocaleString()} /{' '}
          {projectData?.usage?.usageLimit.toLocaleString()} Requests
        </div>

        <div className={`fw-semibold text-white`}>Total API requests</div>
      </div>
    </div>
  )
}

export {TotalRequestWidget}
